import * as Actions from "../../actions/accounts/index";

const initialState = {
  success: false,
  message: "",
  data: ""
};

const avatar = function(state = initialState, action) {
  switch (action.type) {
    case Actions.UPLOAD_ACCOUNT_AVATAR: {
      return {
        ...state,
        success: true,
        message: "Successfully uploaded new Account image",
        data: action.payload
      };
    }

    case Actions.REMOVE_ACCOUNT_AVATAR: {
      return {
        ...state,
        success: true,
        message: "Successfully removed Account image",
        data: ""
      };
    }

    case Actions.ERROR_ACCOUNT_AVATAR: {
      return {
        ...state,
        success: false,
        message: action.payload,
        data: ""
      };
    }

    case Actions.CLEAR_STATE_AVATAR: {
      return {
        success: false,
        message: ""
      };
    }

    default: {
      return state;
    }
  }
};

export default avatar;
