import axios from 'axios';
import moment from 'moment';

export const getAllAccount = () => axios.get('/account');

export const createAccount = body => axios.post('/account', body);

export const getAnAccount = id => axios.get(`/account/${id}`);

export const editAccount = (acc_id, body) =>
  axios.put(`/account/${acc_id}`, body);

export const removeAccount = acc_id => axios.delete(`/account/${acc_id}`);

export const showUserDetails = id => axios.get(`/user/${id}`);

export const getAllUsers = () => axios.get(`/user`);

export const showLocation = loc_id => axios.get(`/location/${loc_id}`);

export const addLocationToAccount = (acc_id, body) =>
  axios.post(`/account/${acc_id}/location`, {
    commission_date: null,
    decommission_date: null,
    ...body
  });

export const editLocation = (loc_id, body) =>
  axios.put(`/location/${loc_id}`, {
    ...body,
    commission_date:
      body.commission_date == null
        ? null
        : moment(body.commission_date)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
    decommission_date:
      body.decommission_date == null
        ? null
        : moment(body.decommission_date)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
    status: body.status == 'true' ? true : false
  });

export const removeLocation = loc_id => axios.delete(`/location/${loc_id}`);

export const assignUsersToAccount = (acc_id, user_id) =>
  axios
    .post(`/account/${acc_id}/user`, { user_id: user_id })
    .then(() => axios.post(`/user/${user_id}/claims`));

export const deAssignUsersToAccount = (acc_id, user_id) =>
  axios
    .delete(`/account/${acc_id}/user`, { data: { user_id: user_id } })
    .then(() => axios.post(`/user/${user_id}/claims`));

export const assignUsersToLocation = (loc_id, user_id) =>
  axios
    .post(`/location/${loc_id}/user`, { user_id: user_id })
    .then(() => axios.post(`/user/${user_id}/claims`));

export const deAssignUsersToLocation = (loc_id, user_id) =>
  axios
    .delete(`/location/${loc_id}/user`, { data: { user_id: user_id } })
    .then(() => axios.post(`/user/${user_id}/claims`));

export const getUsersTiedToAccount = account_id =>
  axios.get(`/account/${account_id}/user`);

export const getAccountlessUsers = () => axios.get(`/user/filter/user`);
