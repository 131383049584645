import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

class ZoneGraph extends Component {
  state = {
    label: {},
  };

  UNITS = {
    ELECTRICITY: {
      unit: 'kWh',
      key: 'energy_consumed',
    },
    WATER: {
      unit: 'Cu M',
      key: 'water',
    },
    CO2: {
      unit: 'kg',
      key: 'carbon',
    },
    GEN: {
      unit: 'kWh',
      key: 'gen',
    },
  };

  // returns a list of total value labels for stacked bars
  // TotalLabels = ({ bars, yScale }) => {
  //   // space between top of stacked bars and total label
  //   const label = this.state;
  //   const labelMargin = 20;
  //   return bars.map(({ data: { data, indexValue }, x, width }, i) => {
  //     // sum of all the bar values in a stacked bar
  //     const total = Object.keys(data)
  //       //f ilter out whatever your indexBy value is
  //       .filter((key) => key !== indexBy)
  //       .reduce((a, key) => a + data[key], 0);

  //     let isShow = label && indexValue === label.indexValue;

  //     return isShow ? (
  //       <g
  //         transform={`translate(${x}, ${yScale(total) - labelMargin})`}
  //         key={`${indexValue}-${i}`}
  //       >
  //         <text
  //           // add any class to the label here
  //           className="bar-total-label"
  //           x={width / 2}
  //           y={labelMargin / 2}
  //           textAnchor="middle"
  //           alignmentBaseline="central"
  //           // add any style to the label here
  //           style={{
  //             fill: 'rgb(51, 51, 51)',
  //           }}
  //         >
  //           124
  //         </text>
  //       </g>
  //     ) : null;
  //   });
  // };

  render() {
    const { label } = this.state;
    const { color, rawData, range, filterKey } = this.props;

    const getMappedDataByKey = () => {
      if (range === 'monthly') {
        return rawData
          .filter((_data) => {
            // MONTH Filter
            // make sure data are in same year as current year
            // make sure data is available
            return (
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'year'
              ) && _data[this.UNITS[filterKey].key]
            );
          })
          .sort(function(x, y) {
            return x.timestamp.seconds * 1000 - y.timestamp.seconds * 1000;
          })
          .map((_data) => {
            return {
              [range]: moment(_data.timestamp.seconds * 1000).format('MMM'),
              [this.UNITS[filterKey].key]: _data[this.UNITS[filterKey].key],
            };
          });
      } else if (range === 'daily') {
        return rawData
          .filter((_data) => {
            // DAYS Filter
            // make sure data are in same month as current month
            // make sure data is available
            return (
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'year'
              ) &&
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'month'
              ) &&
              _data[this.UNITS[filterKey].key]
            );
          })
          .sort(function(x, y) {
            return x.timestamp.seconds * 1000 - y.timestamp.seconds * 1000;
          })
          .map((_data) => {
            return {
              [range]: moment(_data.timestamp.seconds * 1000).format('DD-MMM'),
              [this.UNITS[filterKey].key]: _data[this.UNITS[filterKey].key],
            };
          });
      } else if (range === 'half_hourly') {
        return rawData
          .filter((_data) => {
            // HALF_HOURLY Filter
            // make sure data are in same day as current day
            // make sure data is available
            return (
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'year'
              ) &&
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'month'
              ) &&
              moment(_data.timestamp.seconds * 1000).isSame(
                new Date(),
                'day'
              ) &&
              _data[this.UNITS[filterKey].key]
            );
          })
          .sort(function(x, y) {
            return x.timestamp.seconds * 1000 - y.timestamp.seconds * 1000;
          })
          .map((_data) => {
            return {
              [range]: moment(_data.timestamp.seconds * 1000).format('hh:mm a'),
              [this.UNITS[filterKey].key]: _data[this.UNITS[filterKey].key],
            };
          });
      }
    };

    const mapppedByKey = getMappedDataByKey();

    let customTickValues =
      mapppedByKey.length > 5
        ? mapppedByKey
            .filter((_value, index) => {
              return index % 3 === 1;
            })
            .map((_data) => {
              return _data[range];
            })
        : mapppedByKey.map((_data) => {
            return _data[range];
          });

    return (
      <div
        style={{
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.props.isLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : mapppedByKey.length > 0 ? (
          <>
            <Typography style={{ paddingLeft: 20 }}>
              {this.UNITS[filterKey].unit}
            </Typography>
            <ResponsiveBar
              theme={{ fontSize: 10 }}
              data={mapppedByKey}
              keys={[this.UNITS[filterKey].key]}
              indexBy={range}
              margin={{ top: 50, bottom: 50, left: 50 }}
              padding={0.3}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 10,
                tickValues: customTickValues,
                format: (v) => v,
              }}
              enableLabel={false}
              // add TotalLabels after bars
              layers={[
                'grid',
                'axes',
                'bars',
                // this.TotalLabels,
                'markers',
                'legends',
              ]}
              // onClick={(props) => {
              //   if (label && props.indexValue === label.indexValue) {
              //     this.setState({
              //       label: null,
              //     });
              //   } else {
              //     this.setState({
              //       label: props,
              //     });
              //   }
              // }}
              colors={({ id, data }) => {
                if (label && data[range] === label.indexValue) {
                  return `rgba(${color},1)`;
                } else {
                  return `rgba(${color},0.5)`;
                }
              }}
            />
          </>
        ) : (
          <div className="flex items-center justify-end h-full">
            No Data Available
          </div>
        )}
      </div>
    );
  }
}

export default ZoneGraph;
