import { authRoles } from "auth";
import ManageLocations from "./ManageLocations";
import AddLocation from "./AddLocation";
import LocationPage from "../accounts/account/LocationPage";
export const LocationsConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false
        }
      }
    }
  },
  auth: authRoles.location,
  routes: [
    {
      path: "/manage_locations",
      component: ManageLocations,
      exact: true
    },
    {
      path: "/manage_locations/add_location",
      component: AddLocation,
      exact: true
    },
    {
      path: "/manage_account/:acc_id/location/:loc_id",
      component: LocationPage,
      exact: true
    }
  ]
};
