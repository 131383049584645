import React from 'react';

const StackedInverterIcon = ({ colorCode }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M78.668,127.996c0,7.331-5.976,13.308-13.324,13.308c-7.331,0-13.308-5.977-13.308-13.308
		c0-5.415,3.251-10.076,7.893-12.158v2.101c0,2.634,2.148,4.781,4.793,4.781c2.63,0,4.777-2.147,4.777-4.781v-2.595
		C74.817,117.096,78.668,122.101,78.668,127.996z M113.231,165.75c-0.023,0-0.045,0.002-0.068,0.002h-8.408v6.367
		c0,1.757-1.43,3.192-3.191,3.192H82.439c-1.761,0-3.192-1.434-3.192-3.192v-6.367H53.752v6.367c0,1.757-1.43,3.192-3.187,3.192
		H31.436c-1.761,0-3.175-1.434-3.175-3.192v-6.367H17.539C7.873,165.752,0,157.879,0,148.213V17.523C0,7.857,7.873,0,17.539,0
		h95.624c0.023,0,0.045,0.002,0.068,0.002C103.596,0.039,95.76,7.88,95.76,17.523v130.69
		C95.76,157.856,103.596,165.713,113.231,165.75z M48.702,30.285c0,2.634,2.132,4.781,4.777,4.781h25.496
		c2.646,0,4.793-2.148,4.793-4.781c0-2.645-2.148-4.793-4.793-4.793H53.479C50.833,25.492,48.702,27.639,48.702,30.285z
		 M88.223,127.996c0-11.583-8.639-21.167-19.815-22.667c-0.878-1.062-2.205-1.737-3.684-1.737c-1.595,0-3.004,0.778-3.876,1.973
		c-10.463,2.095-18.38,11.35-18.38,22.431c0,12.606,10.271,22.878,22.878,22.878C77.967,150.874,88.223,140.603,88.223,127.996z
		 M149.478,172.119v-6.367h-10.721c-9.666,0-17.539-7.873-17.539-17.539V17.523C121.218,7.857,129.09,0,138.757,0H119.66
		c-9.666,0-17.539,7.857-17.539,17.523v130.69c0,9.666,7.873,17.539,17.539,17.539h10.721v6.367c0,1.757,1.415,3.192,3.175,3.192
		h19.097C150.892,175.31,149.478,173.876,149.478,172.119z M174.744,172.119v-6.367h-10.721c-9.666,0-17.539-7.873-17.539-17.539
		V17.523C146.484,7.857,154.357,0,164.023,0h-19.097c-9.666,0-17.539,7.857-17.539,17.523v130.69
		c0,9.666,7.873,17.539,17.539,17.539h10.721v6.367c0,1.757,1.415,3.192,3.175,3.192h19.097
		C176.158,175.31,174.744,173.876,174.744,172.119z"
    />
  </svg>
);

export default StackedInverterIcon;
