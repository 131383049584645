import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as locationActions from '../store/actions';
import ExpansionPanel from './AlertsAndAlarmsExpansionPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LeftArrowIcon from '../../../assets/leftArrowIcon';
import RightArrowIcon from '../../../assets/rightArrowIcon';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import {
  utilGetInitialPageAlarmAndAlerts,
  utilGetNextPageAlarmAndAlerts,
  utilGetPrevPageAlarmsAndAlerts,
  utilGetAlarmCollectionCount
} from './utils';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  },
  title: {
    fontSize: '2.0rem',
    textTransform: 'uppercase'
  },
  wrapper: {
    height: '100%',
    marginBottom: '100px'
  }
});

class AlertsAlarmsTab extends Component {
  state = {
    isLoading: false,
    data: [],
    lastField: '',
    firstField: '',
    page: 1,
    alarmCount: 0
  };

  setIsLoadingTrue = () => {
    this.setState({
      isLoading: true
    });
  };

  setIsLoadingFalse = () => {
    this.setState({
      isLoading: false
    });
  };

  getInitialQueryAlarmAndAlerts = (accountId, locationId) => {
    utilGetInitialPageAlarmAndAlerts(accountId, locationId).onSnapshot(
      documentSnapshot => {
        let data = [];
        let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
        documentSnapshot.forEach(doc => {
          data.push(doc.data());
        });

        this.setState(
          {
            data,
            lastField,
            page: 1
          },
          () => this.setIsLoadingFalse()
        );
      }
    );
  };

  getNextPageAlarmAndAlerts = (accountId, locationId, paramLastField) => {
    utilGetNextPageAlarmAndAlerts(
      accountId,
      locationId,
      paramLastField
    ).onSnapshot(documentSnapshot => {
      let data = [];
      let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
      let firstField = documentSnapshot.docs[0];

      documentSnapshot.forEach(doc => {
        data.push(doc.data());
      });

      this.setState(
        {
          data,
          lastField,
          firstField
        },
        () => {
          this.setIsLoadingFalse();
        }
      );
    });
    this.setState({
      page: this.state.page + 1
    });
  };

  getPrevPageAlarmAndAlerts = (accountId, locationId, paramFirstField) => {
    utilGetPrevPageAlarmsAndAlerts(
      accountId,
      locationId,
      paramFirstField
    ).onSnapshot(documentSnapshot => {
      let data = [];
      let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
      let firstField = documentSnapshot.docs[0];

      documentSnapshot.forEach(doc => {
        data.push(doc.data());
      });

      this.setState(
        {
          data,
          lastField,
          firstField
        },
        () => {
          this.setIsLoadingFalse();
        }
      );
    });
    this.setState({
      page: this.state.page - 1
    });
  };

  getAlarmCount = (accountId, locationId) => {
    utilGetAlarmCollectionCount(accountId, locationId).onSnapshot(
      collectionSnapshot => {
        let collectionCount = collectionSnapshot.docs.length;
        this.setState({
          alarmCount: collectionCount
        });
        this.getInitialQueryAlarmAndAlerts(
          this.props.match.params.accountId,
          this.props.match.params.locationId
        );
      }
    );
  };

  componentDidMount() {
    const { match } = this.props;
    const { accountId, locationId } = match.params;

    this.setIsLoadingTrue();
    this.getInitialQueryAlarmAndAlerts(accountId, locationId);
    this.getAlarmCount(accountId, locationId);
  }

  render() {
    const {
      isLoading,
      data,
      lastField,
      firstField,
      alarmCount,
      page
    } = this.state;

    const { match, classes } = this.props;
    const { accountId, locationId } = match.params;

    return (
      <>
        <div className="flex w-full justify-between">
          <div></div>
          <div>
            <Typography className={classes.title}>Alert Feed</Typography>
          </div>

          <div>
            <Typography>
              Page : {page} /{alarmCount === 0 ? 1 : Math.ceil(alarmCount / 15)}
            </Typography>
            <IconButton
              onClick={() => {
                this.setIsLoadingTrue();
                this.getPrevPageAlarmAndAlerts(
                  accountId,
                  locationId,
                  firstField
                );
              }}
              disabled={page === 1}
            >
              <LeftArrowIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                this.setIsLoadingTrue();
                this.getNextPageAlarmAndAlerts(
                  accountId,
                  locationId,
                  lastField
                );
              }}
              disabled={alarmCount <= page * 15}
            >
              <RightArrowIcon />
            </IconButton>
          </div>
        </div>

        {isLoading ? (
          <div className="w-full flex justify-center">
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.wrapper}>
            {data.map((alertData, index) => {
              return (
                <ExpansionPanel
                  data={alertData}
                  location={locationId}
                  page={page}
                  index={index}
                />
              );
            })}
          </div>
        )}
      </>
    );
  }
}

export default compose(withStyles(styles), withRouter)(AlertsAlarmsTab);
