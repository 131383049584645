import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import { Form, Field } from "formik";
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik
} from "@massiveinfinity/components/Form/FormikInputs";

import validationSchema from "./validationSchema";
import { createAccount } from "../api";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300"
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none"
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem"
  },
  textLastEdited: {
    color: "#898989",
    fontStyle: "italic",
    marginTop: "0.5rem",
    marginLeft: "2rem",
    fontSize: "1.125rem"
  },
  textSupportLink: {
    color: "#01c0ff !important",
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "1.3rem"
  }
});

class NewAccountPage extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex mt-40 ml-64 flex-wrap">
            {/* <div> */}
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography
              to="/manage_account"
              component={Link}
              className={classes.breadcrumbText}
            >
              Manage Account
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>
              New Account
            </Typography>
          </div>

          <div className="flex mt-16 ml-64">
            <Typography className={classes.titleText} variant="h5">
              New Account Information
            </Typography>
            {/* <Typography className={classes.textLastEdited}>
              Last Edited On: Data unavailable
            </Typography> */}
          </div>
          <div className="mt-16 ml-64">
            <Formik
              onSubmit={(values, { setSubmitting }) =>
                createAccount(values)
                  .then(res => {
                    this.props.showSuccessMessage("Account successfully added");
                    this.props.history.push({
                      pathname: "/manage_account"
                    });
                    setSubmitting(false);
                  })
                  .catch(err => {
                    this.props.showErrorMessage(err.response.data.message);
                    setSubmitting(false);
                  })
              }
              validationSchema={validationSchema}
              initialValues={{
                name: "",
                account_id: "",
                contact_person: "",
                contact_number: "",
                region: "SG"
              }}
            >
              {formikRenderProps => (
                <Form>
                  <div className="flex flex-row">
                    <div className="w-full">
                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Account name:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="name"
                            label="Account Name"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Account ID:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="account_id"
                            label="Account ID"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Point of contact:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="contact_person"
                            label="Point of contact"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Contact number:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="contact_number"
                            label="Contact number"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Region:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="region"
                            label="Region"
                            component={SelectFormik}
                            options={[
                              {
                                label: "SG",
                                value: "SG"
                              }
                            ]}
                            isDisabled
                          />
                        </div>
                      </div>
                      <SubmitButtonFormik formikRenderProps={formikRenderProps}>
                        Submit
                      </SubmitButtonFormik>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage
    },
    dispatch
  );
}

export default withStyles(styles, { withTheme: true })(
  connect(
    null,
    mapDispatchToProps
  )(NewAccountPage)
);
