import axios from 'axios';
import moment from 'moment';
import { BASE_URL } from 'fuse-configs/apiConfig';

const initReportInstance = axios.create({
  baseURL:
    'https://uzagjulji4.execute-api.ap-southeast-1.amazonaws.com/staging',
  timeout: 5000,
  headers: { 'x-api-key': 'nHMN0ma7QOe7op5b9qghVjcyUjbVSCR55sN1X3gY' }
});

const reportUrlInstance = axios.create({
  baseURL:
    'https://uzagjulji4.execute-api.ap-southeast-1.amazonaws.com/staging',
  timeout: 5000,
  responseType: 'json',
  headers: {
    'x-api-key': 'nHMN0ma7QOe7op5b9qghVjcyUjbVSCR55sN1X3gY'
  }
});

const initGenerateReport = ({
  accessToken,
  locationId,
  accountId,
  start_date,
  end_date,
  downloadFileType,
  file
}) => {
  return initReportInstance.post(`/request`, {
    body: {
      report_type: downloadFileType,
      report_url: '',
      service_id: 'pylon-report',
      report_template_id: file,
      data_params: {
        params: {
          report_id: locationId,
          pages: 1,
          reportMode: 0,
          request_builder: {
            url: `${BASE_URL}/account/${accountId}/location/${locationId}/report?startTime=${start_date}T00:00:00.000Z&endTime=${end_date}T15:59:00.000Z`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          },
          callback_response_builder: {
            url: `${BASE_URL}/location/${locationId}/report_status`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        }
      }
    }
  });
};

const getReportStatus = locationId => {
  return new Promise(function(resolve, reject) {
    return axios
      .get(`/location/${locationId}/get_status`)
      .then(res => {
        if (res.data.status === '2') {
          resolve(res);
        } else if (res.data.status === '3') {
          reject('Data not available');
        } else {
          setTimeout(
            () =>
              getReportStatus(locationId)
                .then(res => resolve(res))
                .catch(err => reject(err)),
            3000
          );
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

const checkIfReportBeingDownloaded = locationId => {
  return new Promise(function(resolve, reject) {
    return axios.get(`/location/${locationId}/get_status`).then(res => {
      if (res.data.status === '1') {
        reject('Report not available for now. Please try again later');
      } else {
        resolve('Can be downloaded');
      }
    });
  });
};

const getPDFLink = hashURL => {
  return reportUrlInstance.post(`/retrivereport/${hashURL}`);
};

const setReportPendingStatus = locationId =>
  axios.post(`location/${locationId}/report_status`, {
    status: '1',
    report_id: locationId
  });

export const sequentialDownloadFile = ({
  accessToken,
  locationId,
  accountId,
  start_date,
  end_date,
  downloadFileType,
  filesTemplateId
}) => {
  return filesTemplateId.reduce(
    (promiseChain, currentTask) =>
      promiseChain.then(() => {
        return checkIfReportBeingDownloaded(locationId)
          .then(() => setReportPendingStatus(locationId))
          .then(() =>
            initGenerateReport({
              accessToken,
              locationId,
              accountId,
              start_date,
              end_date,
              downloadFileType,
              file: currentTask
            })
          )
          .then(() => getReportStatus(locationId))
          .then(res => getPDFLink(res.data.report_url))
          .then(res => {
            const link = document.createElement('a');
            link.href = res.data.url;
            link.click();
          })
          .then(res => Promise.resolve())
          .catch(err => {
            Promise.reject(err);
          });
      }),
    Promise.resolve()
  );
};

const pdfReportTemplateId = ['ca269486-4186-12ba6-a558-g4eg61b2923g'];
const csvReportTemplateId = [
  'pylon-2116-13sq9-a12f-a1gg2186si4f',
  'pylon-4186-12ba6-a558-g4eg61b2923g'
];

export const generateReport = ({
  accessToken,
  locationId,
  accountId,
  start_date,
  end_date,
  downloadFileType
}) => {
  return sequentialDownloadFile({
    accessToken,
    locationId,
    accountId,
    start_date,
    end_date,
    downloadFileType,
    filesTemplateId:
      downloadFileType === 'csv' ? csvReportTemplateId : pdfReportTemplateId
  });
};
