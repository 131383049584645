export const GET_NAVIGATION = "[NAVIGATION] GET NAVIGATION";
export const SET_NAVIGATION = "[NAVIGATION] SET NAVIGATION";
export const RESET_NAVIGATION = "[NAVIGATION] RESET NAVIGATION";

export const SET_DEFAULT_NAVIGATION = "[NAVIGATION] DEFAULT NAVIGATION";
export const SET_ACC_ADMIN_NAVIGATION = "[ACC ADMIN NAVIGATION] SET NAVIGATION";
export const SET_LOC_ADMIN_NAVIGATION = "[LOC ADMIN NAVIGATION] SET NAVIGATION";

export function getNavigation() {
  return {
    type: GET_NAVIGATION
  };
}

export function setNavigation(navigation) {
  return {
    type: SET_NAVIGATION,
    navigation
  };
}

export function resetNavigation() {
  return {
    type: RESET_NAVIGATION
  };
}

export function setAccAdminNavigation() {
  return {
    type: SET_ACC_ADMIN_NAVIGATION
  };
}

export function setDefaultNavigation() {
  return {
    type: SET_DEFAULT_NAVIGATION
  };
}

export function setLocAdminNavigation() {
  return {
    type: SET_LOC_ADMIN_NAVIGATION
  };
}
