import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Typography,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import DisplayAccountType from './DisplayAccountType';
import defaultImg from 'assets/default.svg';
import { getAllUsers, getUserOfAnAccount, getAUser } from '../api';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100vw',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    display: 'flex',
    marginLeft: '6.4rem',
    alignItems: 'flex-end'
    // flex ml-64 items-end
  },
  breadcrumbText: {
    color: '#363636 !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  titleText: {
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem'
  },
  textLastEdited: {
    color: '#898989'
  },
  table: {
    minWidth: 350
    // overflowX: "auto"
  },
  avatar: {
    margin: 10
  }
});

class ManageUsersContent extends Component {
  state = {
    users: [],
    isLoading: false,
    rowsPerPage: 5,
    page: 0,
    editedUsers: []
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    if (this.props.role == 99) {
      getAllUsers().then(res =>
        this.setState({
          users: res.data,
          isLoading: false,
          editedUsers: _.slice(
            res.data,
            this.state.page,
            this.state.rowsPerPage
          )
        })
      );
      // .catch(err => console.log(err));
    } else if (this.props.role == 30) {
      let allAssignedUsers = [];

      getAUser(this.props.uid).then(res => {
        allAssignedUsers.push({
          userid: this.props.uid,
          ...res.data
        });
      });
      // .catch(err => console.log(err));

      async function batchGetMultipleUsers(users) {
        for (let userIndex in users) {
          const resolved = await getUserOfAnAccount(users[userIndex]);
          for (let i = 0; i < resolved.data.length; i++) {
            resolved.data[i].userid = resolved.data[i]['user_id'];
            delete resolved.data[i].user_id;
          }
          allAssignedUsers.push(...resolved.data);
        }
        return allAssignedUsers;
      }
      batchGetMultipleUsers(this.props.assigned_accounts).then(val => {
        let uniqueUsersArray = _.uniqBy(val, 'userid');
        this.setState({
          users: uniqueUsersArray,
          isLoading: false,
          editedUsers: _.slice(
            uniqueUsersArray,
            this.state.page,
            this.state.rowsPerPage
          )
        });
      });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    });
    page == 0
      ? this.setState({
          editedUsers: _.slice(this.state.users, page, this.state.rowsPerPage)
        })
      : this.setState({
          editedUsers: _.slice(
            this.state.users,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          )
        });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      editedUsers: _.slice(
        this.state.users,
        this.state.page,
        event.target.value
      )
    });
  };

  render() {
    const { classes } = this.props;
    const { users, isLoading, editedUsers } = this.state;

    return (
      <div>
        {isLoading ? (
          <div className="flex justify-center mt-64">
            <CircularProgress size={48} />
          </div>
        ) : (
          <div className={classes.root}>
            {/* <div className="flex ml-64 items-end"> */}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Last Activity</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {editedUsers.map(
                  ({
                    name,
                    userid,
                    assigned_accounts,
                    assigned_locations,
                    email,
                    user_role,
                    updated_on,
                    created_on,
                    img_uri
                  }) => (
                    <TableRow key={userid} hover={true}>
                      <TableCell>
                        <Avatar
                          src={img_uri !== '' ? img_uri : defaultImg}
                          // alt={name}
                        />
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        {assigned_accounts[0] ? assigned_accounts[0] : '-'}
                      </TableCell>
                      <TableCell>
                        {assigned_locations[0] ? assigned_locations[0] : '-'}
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>
                        <DisplayAccountType type={user_role} />
                      </TableCell>
                      <TableCell>
                        {updated_on
                          ? moment
                              .unix(updated_on._seconds)
                              .format('DD MMMM YYYY @ HH:mm')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {created_on
                          ? moment
                              .unix(created_on._seconds)
                              .format('DD MMMM YYYY @ HH:mm')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          to={`/manage_users/${userid}`}
                          // variant="contained"
                        >
                          <Icon>edit_icon</Icon>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Table>
            {/* </div> */}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(ManageUsersContent)
);
