export const AllRoleType = [
  {
    label: "Member",
    value: "0"
  },
  {
    label: "Region Admin",
    value: "10"
  },
  {
    label: "Location Admin",
    value: "20"
  },
  {
    label: "Account Admin",
    value: "30"
  },
  {
    label: "Master Admin",
    value: "99"
  }
];

export const RoleTypeWithoutMasterAndRegionAdmin = [
  {
    label: "Member",
    value: "0"
  },
  // {
  //   label: "Region Admin",
  //   value: "10"
  // },
  {
    label: "Location Admin",
    value: "20"
  },
  {
    label: "Account Admin",
    value: "30"
  }
];
