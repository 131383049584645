import AsyncSelectWithMUI from "./AsyncSelectWithMUI";
import { applyWithFormsyToReactSelect } from "../helpers";

const AsyncSelectWithMUIFormsy = applyWithFormsyToReactSelect(
  AsyncSelectWithMUI
);

AsyncSelectWithMUIFormsy.propTypes = {
  ...AsyncSelectWithMUIFormsy.propTypes,
  ...AsyncSelectWithMUI.propTypes
};

export default AsyncSelectWithMUIFormsy;
