import { TimePicker } from "material-ui-pickers";
import fieldToDateTimePicker from "./fieldToDateTimePicker";
import { fieldRenderProps } from "../../types";

const TimePickerFormik = fieldToDateTimePicker(TimePicker);

TimePickerFormik.propTypes = {
  ...fieldRenderProps,
  ...TimePicker.propTypes,
};

TimePickerFormik.defaultProps = {
  variant: "outlined",
};

export default TimePickerFormik;
