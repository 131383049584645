import axios from "axios";

export const getAllAccounts = () => axios.get("/account");

export const addLocationForAnAccount = (accountID, body) =>
  axios.post(`/account/${accountID}/location`, {
    commission_date: null,
    decommission_date: null,
    status: false,
    ...body
  });
