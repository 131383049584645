import firebaseService from 'firebaseService';
import jwtService from 'jwtService';
import {setUserData, setUserDataFirebase, createUserSettingsFirebase} from 'auth/store/actions/user.actions';
import * as Actions from 'store/actions';

import { setUserRole } from 'fuse-configs/globalConfig';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({email, password})
{
    return (dispatch) =>
        jwtService.signInWithEmailAndPassword(email, password)
            .then((user) => {
                    dispatch(setUserData(user));

                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}

export function submitLoginWithFireBase({email, password}, cookies)
{
    firebaseService.init();
    return (dispatch) =>
        firebaseService.signInWithEmailAndPassword(email, password)
        .then(({ user, idToken, idTokenResult }) => {
            console.log("sign in success",user)
            console.log("idToken: ", idToken);

            dispatch(createUserSettingsFirebase(user));
            setUserRole(0);
            // Save the JWT token in the cookies.
            cookies.set(
                'auth_token', 
                idToken, 
                {
                    path: '/'
                }
            );
            
            return dispatch({
                type: LOGIN_SUCCESS
            });
        })
        .catch(error => {
            const requestOverload = 'auth/too-many-requests';

            const usernameErrorCodes = [
                'auth/email-already-in-use',
                'auth/invalid-email',
                'auth/operation-not-allowed',
                'auth/user-not-found',
                'auth/user-disabled'
            ];
            const passwordErrorCodes = [
                'auth/weak-password',
                'auth/wrong-password'
            ];

            const response = {
                email: usernameErrorCodes.includes(error.code) ? error.message : null,
                password: passwordErrorCodes.includes(error.code) ? error.message : null,
                tooManyRequests: requestOverload == error.code ? true : false
            };

            if ( error.code === 'auth/invalid-api-key' )
            {
                dispatch(Actions.showMessage({message: error.message}));
            }

            return dispatch({
                type   : LOGIN_ERROR,
                payload: response
            });
        });
}
