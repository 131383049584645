import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from 'auth/store/actions';
import {bindActionCreators} from 'redux';
import * as Actions from 'store/actions';
import firebaseService from 'firebaseService';
import auth0Service from 'auth0Service';
import jwtService from 'jwtService';

import { withCookies } from 'react-cookie';

class Auth extends Component {
    /*eslint-disable-next-line no-useless-constructor*/
    constructor(props)
    {
        super(props);

        /**
         * Comment the line if you do not use JWt
         */
        this.jwtCheck();

        /**
         * Comment the line if you do not use Auth0
         */
        //this.auth0Check();

        /**
         * Comment the line if you do not use Firebase
         */
        //this.firebaseCheck();
    }

    componentDidUpdate() {
        // This component will be updated whenever there is a change in the state.
        // In this case, we are waiting to see if the user has logout successfully.
        // If so, the user's isAuthenticated flag will return false.
        // Clear the data from Cookies if this happens.
        const { user, cookies } = this.props;
        if (!user.isAuthenticated && cookies.cookies.auth_token) {
            cookies.remove('auth_token', {
                path: '/'
            });
        }
    }

    jwtCheck = () => {
        const { cookies } = this.props;

        jwtService.on('onAutoLogin', () => {
            //this.props.showMessage({message: 'Logging in with JWT'});
            
            /**
             * Sign in and retrieve user data from Api
             */
            jwtService.signInWithToken()
                .then(user => {
                    this.props.setUserData(user);
                    //this.props.showMessage({message: 'Logged in with JWT'});

                    // Initialize Firebase Service here.
                    // Since LOCAL level persistence is applied during login (even by default),
                    // the auth user data should still be stored locally.
                    firebaseService.init();
                })
                .catch(error => {
                    this.props.showMessage({message: error});
                })
        });

        jwtService.on('onAutoLogout', (message) => {
            if ( message )
            {
                this.props.showMessage({message});
            }
            this.props.logout();
        });

        jwtService.init(cookies);
    };

    auth0Check = () => {

        auth0Service.init();

        if ( auth0Service.isAuthenticated() )
        {
            this.props.showMessage({message: 'Logging in with Auth0'});

            /**
             * Retrieve user data from Auth0
             */
            auth0Service.getUserData().then(tokenData => {

                this.props.setUserDataAuth0(tokenData);

                this.props.showMessage({message: 'Logged in with Auth0'});
            })
        }
    };

    firebaseCheck = () => {

        firebaseService.init();

        firebaseService.onAuthStateChanged(authUser => {
            if ( authUser )
            {
                this.props.showMessage({message: 'Logging in with Firebase'});

                /**
                 * Retrieve user data from Firebase
                 */
                firebaseService.getUserData(authUser.uid).then(user => {

                    this.props.setUserDataFirebase(user, authUser);

                    this.props.showMessage({message: 'Logged in with Firebase'});
                })
            }
        });
    };

    render()
    {
        const {children} = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
            logout             : userActions.logoutUser,
            setUserData        : userActions.setUserData,
            setUserDataAuth0   : userActions.setUserDataAuth0,
            setUserDataFirebase: userActions.setUserDataFirebase,
            showMessage        : Actions.showMessage,
            hideMessage        : Actions.hideMessage
        },
        dispatch);
}

function mapStateToProps({ auth }, { cookies }) {
    return {
        cookies: cookies,
        user: auth.user
    };
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Auth));
