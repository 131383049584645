import CreatableSelectWithMUI from "./CreatableSelectWithMUI";
import { applyWithFormsyToReactSelect } from "../helpers";

const CreatableSelectWithMUIFormsy = applyWithFormsyToReactSelect(
  CreatableSelectWithMUI
);

CreatableSelectWithMUIFormsy.propTypes = {
  ...CreatableSelectWithMUIFormsy.propTypes,
  ...CreatableSelectWithMUI.propTypes
};

export default CreatableSelectWithMUIFormsy;
