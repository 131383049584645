import applyMUIToReactSelect from "../helpers/applyMUIToReactSelect";
import AsyncSelect from "react-select/lib/Async";
import PropTypes from "prop-types";

const AsyncSelectWithMUI = applyMUIToReactSelect(AsyncSelect);

AsyncSelectWithMUI.displayName = "AsyncSelectWithMUI";

AsyncSelectWithMUI.propTypes = {
  ...AsyncSelectWithMUI.propTypes,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })
    ),
    PropTypes.bool
  ]),
  loadOptions: PropTypes.func,
  cacheOptions: PropTypes.bool
};

export default AsyncSelectWithMUI;
