import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  content: PropTypes.node
};

const defaultProps = {};

const styles = theme => ({});

class PylonForm extends Component {
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { classes, content } = this.props;

    return (
      <div className="p-16 sm:p-24 h-full">
        <div className="h-full flex">
          <div className="w-1/15"></div>
          <div className="w-13/15 flex flex-1">{content}</div>
          <div className="w-1/15"></div>
        </div>
      </div>
    );
  }
}

PylonForm.propTypes = propTypes;
PylonForm.defaultProps = defaultProps;

export default withStyles(styles, {
  withTheme: true
})(PylonForm);
