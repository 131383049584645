import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FusePageSimple } from "@fuse";
import AccountTab from "./tabs/AccountTab";

const styles = theme => ({});

class Account extends Component {
  render() {
    const { classes } = this.props;
    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot
        }}
        className="min-h-72 h-72 sm:h-136 sm:min-h-136"
        content={<AccountTab />}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Account));
