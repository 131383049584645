import SelectWithMUI from "./SelectWithMUI";
import applyWithFormsyToReactSelect from "../helpers/applyWithFormsyToReactSelect";

const SelectWithMUIFormsy = applyWithFormsyToReactSelect(SelectWithMUI);
SelectWithMUIFormsy.propTypes = {
  ...SelectWithMUIFormsy.propTypes,
  ...SelectWithMUI.propTypes
};

export default SelectWithMUIFormsy;
