import React, { Component } from "react";
import { withFormsy, propTypes } from "formsy-react";
import { TextField, withStyles } from "@material-ui/core";

class MIAutoFillTextFieldFormsy extends Component {
  static propTypes = {
    ...propTypes
  };

  state = {
    autofilled: false
  };

  handleAutoFill = e => {
    this.setState({ autofilled: e.animationName === "onAutoFillStart" });
  };

  render() {
    const {
      hasValue,
      getValue,
      setValue,
      resetValue,
      getErrorMessage,
      getErrorMessages,
      isValid,
      isValidValue,
      setValidations,
      validationError,
      validationErrors,
      isRequired,
      showRequired,
      showError,
      isPristine,
      isFormDisabled,
      isFormSubmitted,
      classes,
      ...otherProps
    } = this.props;
    //separating props injected by withFormsy HOC and withStyle HOC from props that are passed to this component

    const { input } = classes;

    return (
      <TextField
        {...otherProps}
        value={getValue()}
        onChange={e => setValue(e.target.value)}
        error={showError()}
        helperText={getErrorMessage()}
        InputProps={{
          ...this.props.InputProps,
          classes: { input }
        }}
        InputLabelProps={{
          shrink: this.state.autofilled || getValue()
          //shrink label if the input is autofilled or has value
        }}
        inputProps={{ onAnimationStart: this.handleAutoFill }}
      />
    );
  }
}

const styles = theme => ({
  "@keyframes onAutoFillStart": {},

  "@keyframes onAutoFillCancel": {},

  input: {
    "&:-webkit-autofill": {
      animationName: "onAutoFillStart"
    },
    "&:not(:-webkit-autofill)": {
      animationName: "onAutoFillCancel"
    }
  }
});

export default withFormsy(withStyles(styles)(MIAutoFillTextFieldFormsy));
