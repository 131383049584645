import React, { Component } from "react";
import { SelectFormik } from "@massiveinfinity/components/Form/FormikInputs";
import { getAllAccounts } from "./api";
import _ from "lodash";

class AccountsSelect extends Component {
  state = {
    accounts: []
  };

  componentDidMount() {
    getAllAccounts()
      .then(res => this.setFilterToState(res.data))
      .catch(err => console.log(err));
  }

  setFilterToState = accountsArray => {
    let newArr = _.map(accountsArray, account =>
      _.pick(account, ["accountdata"])
    );

    let accountSelectArr = _.map(newArr, account =>
      _.pick(account.accountdata, ["account_id", "name"])
    );
    const mutatedAccArr = accountSelectArr.map(account => {
      return {
        label: account.name,
        value: account.account_id
      };
    });
    this.setState({
      accounts: mutatedAccArr
    });
  };

  render() {
    const { accounts } = this.state;
    return <SelectFormik options={accounts} {...this.props} />;
  }
}

export default AccountsSelect;
