import * as Yup from "yup";

export default Yup.object()
  .shape({
    name: Yup.string().required("Name is a required field!"),
    account_id: Yup.string().required("Account ID is a required field!"),
    contact_person: Yup.string().required(
      "Point of Contact is a required field!"
    ),
    contact_number: Yup.number()
      .typeError("Contact Number must be a ‘number’ type!")
      .required("Contact Number is a required field!")
    //   region: Yup.array()
    //     .of(Yup.string())
    //     .required()
  })
  .required();
