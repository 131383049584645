import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300"
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none"
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem"
  },
  textLastEdited: {
    color: "#898989"
  },
  table: {
    minWidth: 700
  }
});

class ManageUsersHeader extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex mt-40 ml-64">
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>
              Manage Users
            </Typography>
          </div>
          <div className="flex mt-16 ml-64">
            <Typography className={classes.titleText} variant="h5">
              Users
            </Typography>
            {this.props.role >= 30 ? (
              <Typography
                to="/manage_users/new_user"
                component={Link}
                className={classes.linkText}
              >
                + Add New Users
              </Typography>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role } = auth.user;

  return {
    role
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    null
  )(ManageUsersHeader)
);
