import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import BellAlert from '../../../assets/bellAlert';
import TriangleAlert from '../../../assets/triangleAlert';

const constColorCode = {
  major: '#ec2027',
  minor: '#f79420',
  warning: '#f7ec13',
  default: '#666766',
  deactivated: '#5751BA'
};

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // maxWidth: '25%',
    // width: 25%
    flexWrap: 'wrap',
    // paddingRight: '5px',
    padding: '5px'
  },
  heading2: {
    fontSize: theme.typography.pxToRem(12),
    // width: '35%',
    flexWrap: 'wrap',
    paddingRight: '5px'
  },
  fullWidthText: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%',
    flexWrap: 'wrap',
    paddingRight: '5px'
  },
  deactivedExpansionSummary: {
    opacity: 0.6,
    backgroundColor: '#B0B2B0'
  },
  deactivatedExpansionDetails: {
    opacity: 0.6,
    backgroundColor: '#B0B2B0'
  }
});

class ControlledExpansionPanels extends Component {
  state = {
    expanded: null
  };

  componentDidMount() {
    const { classes, data, location, page, index } = this.props;

    if (page === 1 && index <= 2) {
      this.setState({
        expanded: `panel${index}`
      });
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  getIconDisplay = status => {
    let iconToDisplay = '';
    switch (status) {
      case 'major':
        iconToDisplay = (
          <TriangleAlert colorCode={constColorCode.major}></TriangleAlert>
        );
        break;
      case 'minor':
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.minor}></BellAlert>
        );
        break;
      case 'warning':
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.warning}></BellAlert>
        );
        break;
      default:
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.default}></BellAlert>
        );
        break;
    }
    return iconToDisplay;
  };

  getStatusColorText = status =>
    status ? (
      <p style={{ color: `${constColorCode.major}` }}> Active</p>
    ) : (
      <p style={{ color: `${constColorCode.deactivated}` }}>Deactivated</p>
    );

  getSeverityColorText = severity => {
    let severityColoredtext = '';
    switch (severity) {
      case 'major':
        severityColoredtext = (
          <p style={{ color: constColorCode.major }}>
            {severity.toUpperCase()}
          </p>
        );
        break;
      case 'minor':
        severityColoredtext = (
          <p style={{ color: constColorCode.minor }}>
            {severity.toUpperCase()}
          </p>
        );
        break;
      case 'warning':
        severityColoredtext = (
          <p style={{ color: constColorCode.warning }}>
            {severity.toUpperCase()}
          </p>
        );
        break;
      default:
        severityColoredtext = <p></p>;
        break;
    }
    return severityColoredtext;
  };

  render() {
    const { classes, data, location, page, index } = this.props;
    const {
      alarm,
      asset,
      cause_id,
      severity,
      solution,
      state,
      status_code,
      timestamp
    } = data;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          style={{
            boxShadow: 'none',
            border: '2px solid #DBDBDD',
            margin: '10px'
          }}
          expanded={expanded === `panel${index}`}
          onChange={this.handleChange(`panel${index}`)}
        >
          <ExpansionPanelSummary
            className={!state ? classes.deactivedExpansionSummary : null}
          >
            <div className="flex justify-center items-center pr-4">
              {this.getIconDisplay(severity)}
            </div>
            <div className="w-full">
              <div
                className={
                  window.screen.width <= 500
                    ? 'w-full flex justify-between flex-wrap flex-col'
                    : 'w-full flex justify-between flex-wrap'
                }
              >
                <Typography className={classes.heading}>
                  Alarm: {alarm}
                </Typography>
                <Typography className={classes.heading}>
                  <p className="float-left pr-4">Status: </p>
                  {this.getStatusColorText(state)}
                </Typography>
                <Typography className={classes.heading}>
                  <p className="float-left pr-4">Severity: </p>
                  {this.getSeverityColorText(severity)}
                </Typography>
                <Typography className={classes.heading2}>
                  Alert entry on{' '}
                  <strong>
                    {moment
                      .unix(timestamp.seconds)
                      .format('dddd, DD/MM/YYYY [at] HHmm') + ' hrs'}
                  </strong>
                </Typography>
              </div>

              <div
                className={
                  window.screen.width <= 500
                    ? 'w-full flex flex-col'
                    : 'w-full flex'
                }
              >
                <Typography className={classes.heading}>
                  Location: {location}
                </Typography>
                <Typography className={classes.heading}>
                  Asset: {asset}
                </Typography>
                <Typography className={classes.heading}></Typography>
                <Typography className={classes.heading2}></Typography>
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={!state ? classes.deactivatedExpansionDetails : null}
          >
            <div className="flex justify-center items-center pr-4 opacity-0">
              {this.getIconDisplay(severity)}
            </div>
            <div className="flex-col w-full text-justify">
              <Typography className={classes.fullWidthText}>
                Cause: {cause_id}
              </Typography>

              <Typography className={classes.fullWidthText}>
                Solution: {solution}
              </Typography>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(ControlledExpansionPanels);
