import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik,
  DateTimePickerFormik
} from '@massiveinfinity/components/Form/FormikInputs';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import _ from '@lodash';
import {
  canSubmitFormik,
  getSubmitFuncFormik,
  isSubmittingFormik
} from '../../../../@massiveinfinity/components/Form/FormikInputs/helpers';

import UploadLocationImage from './UploadLocationImage';
import pin_blue from '../../../assets/pin_blue.png';
import { GOOGLE_BROWSER_KEY } from '../../../../fuse-configs/apiConfig';
import {
  showLocation,
  editLocation,
  removeLocation,
  assignUsersToLocation,
  deAssignUsersToLocation
} from '../api';
import { sectorSelect, timezoneSelect, statusSelect } from '../const';
import locationValidationSchema from './locationValidationSchema';
import locationValidationSchemaForNonMaster from './locationValidationSchemaForNonMaster';

import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UsersSelect from './UsersSelect';
import firebaseService from 'firebaseService';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  breadcrumbText: {
    color: '#363636 !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  titleText: {
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem'
  },
  textLastEdited: {
    color: '#898989',
    fontStyle: 'italic',
    marginTop: '0.5rem',
    marginLeft: '2rem',
    fontSize: '1.125rem'
  },
  textSupportLink: {
    color: '#01c0ff !important',
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '1.3rem'
  },
  submitButton: {
    backgroundColor: '#68f72a',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase'
  },
  cancelButton: {
    backgroundColor: '#5d5e5c',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase',
    color: '#cfd0d1'
  }
});

const Marker = () => {
  return <img style={{ height: 35, width: 25 }} src={pin_blue} alt="Logo" />;
};

class LocationPage extends Component {
  state = {
    data: {
      name: '',
      location_id: '',
      sector: '',
      address: '',
      timezone: '',
      status: '',
      commission_date: '',
      decommission_date: '',
      invested_amt: '',
      panel_count: '',
      panel_rating: '',
      panel_brand: '',
      system_size: '',
      coordinate: {
        _latitude: 0,
        _longitude: 0
      },
      users: []
    },
    center: {
      lat: 1.35,
      lng: 103.82
    },
    zoom: 12,
    isLoading: false,
    open: false,
    passwordPrompt: false
  };

  getLocDataToSetState = () => {
    showLocation(this.props.match.params.loc_id).then(res => {
      this.setState({
        data: {
          ...res.data,
          status: res.data.status == true ? 'true' : 'false',
          commission_date:
            res.data.commission_date == null
              ? null
              : moment
                  .unix(res.data.commission_date._seconds)
                  .format('YYYY-MM-DDTHH:mm:ss'),
          decommission_date:
            res.data.decommission_date == null
              ? null
              : moment
                  .unix(res.data.decommission_date._seconds)
                  .format('YYYY-MM-DDTHH:mm:ss')
        },
        isLoading: false,
        center: {
          lat: res.data.coordinates._latitude,
          lng: res.data.coordinates._longitude
        }
      });
    });
    // .catch(err => {
    //   this.props.showErrorMessage("Error");
    // });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getLocDataToSetState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.avatar !== this.props.avatar) {
      this.getLocDataToSetState();
    }
  }

  renderMarker = () => {
    return <Marker lat={this.state.center.lat} lng={this.state.center.lng} />;
  };

  promptDialog = () => {
    this.setState({
      open: true
    });
  };

  closeDialogPrompt = () => {
    this.setState({
      open: false
    });
  };

  openPasswordPrompt = () => {
    this.closeDialogPrompt();
    this.setState({
      passwordPrompt: true
    });
  };

  closePasswordPrompt = () => {
    this.setState({
      passwordPrompt: false
    });
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  };

  confirmDeleteLocation = location_id => {
    removeLocation(location_id)
      .then(res => {
        this.props.showSuccessMessage('Location Successfully deleted');
        // this.setState({ open: false });
        this.props.history.push({
          pathname: '/manage_account'
        });
      })
      .catch(err => {
        this.props.showErrorMessage(err.response.data.message);
        this.setState({ open: false });
      });
  };

  determinePostOrDeleteUser = (loc_id, initialUser, editedUser) => {
    //async function to assign users
    async function batchAssignUsersToLocation(arr) {
      let results = [];
      for (let i = 0; i < arr.length; i++) {
        const resolved = await assignUsersToLocation(loc_id, arr[i]);
        results.push(resolved);
      }
      return results;
    }

    //async function to deassign users
    async function batchDeassignUsersToLocation(arr) {
      let results = [];
      for (let i = 0; i < arr.length; i++) {
        const resolved = await deAssignUsersToLocation(loc_id, arr[i]);
        results.push(resolved);
      }
      return results;
    }

    //Start condition after this line
    if (initialUser.length == 0) {
      //initially no users assigned, then assign whatever user input to api call
      // console.log('Initial 0');
      batchAssignUsersToLocation(editedUser).then(res => {
        this.props.showSuccessMessage('Form successfully edited');
        this.getLocDataToSetState();
      });
      // .catch(err => {
      //   this.props.showErrorMessage(err.response.data.message);
      // });
    } else {
      // console.log("Initial user", initialUser);
      // console.log("Edited user", editedUser);
      const initialDiff = _.difference(initialUser, editedUser);
      let deassignedUser = _.remove(initialDiff, function(item) {
        return typeof item == 'string';
      });
      const editedDiff = _.difference(editedUser, initialUser);
      let assignedUser = _.remove(editedDiff, function(item) {
        return typeof item == 'string';
      });

      // console.log('Initial diff', deassignedUser);
      // console.log('Edited diff', assignedUser);

      batchAssignUsersToLocation(assignedUser)
        .then(() => batchDeassignUsersToLocation(deassignedUser))
        .then(() => {
          this.props.showSuccessMessage('Form successfully edited');
          this.getLocDataToSetState();
        });

      // if (editedDiff.length == 0) {
      //   //only deassign users from initial value
      //   console.log("Deassign only", initialDiff);
      //   batchDeassignUsersToLocation(initialDiff).then(res => {
      // this.props.showSuccessMessage("Form successfully edited");
      // this.getLocDataToSetState();
      //   });
      //   // .catch(err => {
      //   //   this.props.showErrorMessage(err.response.data.message);
      //   // });
      // } else if (initialDiff.length == 0) {
      //   //only assign extra user from initial value
      //   console.log("Assign extra only");
      //   batchAssignUsersToLocation(editedDiff).then(res => {
      //     this.props.showSuccessMessage("Form successfully edited");
      //     this.getLocDataToSetState();
      //   });
      //   // .catch(err => {
      //   //   this.props.showErrorMessage(err.response.data.message);
      //   // });
      // } else {
      //   console.log("Removing user", initialDiff);
      //   console.log("Assigning user", editedDiff);

      //   batchDeassignUsersToLocation(initialDiff)
      //     .then(() => {
      //       console.log("Finish deassign");
      //       return batchAssignUsersToLocation(editedDiff);
      //     })
      //     .then(res => {
      //       this.props.showSuccessMessage("Form successfully edited");
      //       this.getLocDataToSetState();
      //     });
      //   // .catch(err => {
      //   //   this.props.showErrorMessage(err.response.data.message);
      //   // });
      // }
    }
  };

  onKeyDown = keyEvent => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    const isMobile = this.isMobileDevice();

    return (
      <div className="w-full pb-64 mb-32">
        <div className="flex flex-col">
          <div className="flex mt-40 ml-64 flex-wrap">
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            {this.props.location.state ? (
              <div>
                <Typography
                  to="/manage_locations"
                  component={Link}
                  className={classes.breadcrumbText}
                >
                  Manage Location
                </Typography>
              </div>
            ) : (
              <div>
                <Typography
                  to="/manage_account"
                  component={Link}
                  className={classes.breadcrumbText}
                >
                  Manage Account
                </Typography>
              </div>
            )}
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>
              Edit Location
            </Typography>
          </div>
          <div className="flex mt-16 pl-64 flex-row w-full justify-between">
            <div>
              <Typography className={classes.titleText} variant="h5">
                Location Information
              </Typography>
            </div>
            <div>
              <Button className="text-red" onClick={this.promptDialog}>
                Delete Location <Icon>delete</Icon>
              </Button>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="flex justify-center mt-64">
              <CircularProgress size={48} />
            </div>
          ) : (
            <div>
              <div className="w-full">
                <UploadLocationImage
                  location_id={data.location_id}
                  src={data.img_uri}
                />
              </div>
              <div className="mt-16 ml-64">
                <Formik
                  onSubmit={(values, { setSubmitting }) => {
                    editLocation(this.props.match.params.loc_id, values)
                      .then(() =>
                        this.determinePostOrDeleteUser(
                          this.props.match.params.loc_id,
                          data.users,
                          values.users
                        )
                      )
                      .then(() => setSubmitting(false))
                      .catch(err => {
                        this.props.showErrorMessage(err.response.data.message);
                        setSubmitting(false);
                      });
                  }}
                  validationSchema={
                    this.props.role == 99
                      ? locationValidationSchema
                      : locationValidationSchemaForNonMaster
                  }
                  initialValues={this.state.data}
                  enableReinitialize
                >
                  {formikRenderProps => (
                    <Form onKeyDown={this.onKeyDown}>
                      {/* <div className="flex flex-row items-center"> */}
                      <div
                        style={
                          isMobile
                            ? { display: 'flex', flexDirection: 'column' }
                            : { display: 'flex', flexDirection: 'row' }
                        }
                      >
                        <div
                          className="flex-1"
                          style={{ paddingRight: '2rem' }}
                        >
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Location name:</div>
                            <div className="w-full">
                              <Field
                                name="name"
                                label="Location Name"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Location ID:</div>
                            <div className="w-full">
                              <Field
                                name="location_id"
                                label="Location ID"
                                component={TextFieldFormik}
                                fullWidth
                                disabled
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Sector:</div>
                            {/* <div className="w-1/3"> */}
                            <div className="w-full">
                              <Field
                                name="sector"
                                label="Sector"
                                component={SelectFormik}
                                options={sectorSelect}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Address:</div>
                            <div className="w-full">
                              <Field
                                name="address"
                                label="Address"
                                component={TextFieldFormik}
                                multiline
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Timezone:</div>
                            <div className="w-full">
                              <Field
                                name="timezone"
                                label="Time zone"
                                component={SelectFormik}
                                options={timezoneSelect}
                                isDisabled
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Status:</div>
                            <div className="w-full">
                              <Field
                                name="status"
                                label="Status"
                                component={SelectFormik}
                                options={statusSelect}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Commission Date:</div>
                            <div className="w-full">
                              <Field
                                name="commission_date"
                                component={DateTimePickerFormik}
                                emptyLabel="MMMM Do hh:mm a"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Decommission Date:</div>
                            <div className="w-full">
                              <Field
                                name="decommission_date"
                                component={DateTimePickerFormik}
                                emptyLabel="MMMM Do hh:mm a"
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Invested Amount:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="invested_amt"
                                label="SGD $"
                                component={TextFieldFormik}
                                fullWidth
                                disabled={this.props.role == 99 ? false : true}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Tariff:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="tariff"
                                label="SGD $"
                                component={TextFieldFormik}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      /kWh
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Panel Brand:</div>
                            <div className="w-full">
                              <Field
                                name="panel_brand"
                                label="Panel brand"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Panel Count:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="panel_count"
                                label="Panel count"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Panel Rating:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="panel_rating"
                                label="Panel rating"
                                component={TextFieldFormik}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      /W
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">System Size:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="system_size"
                                label="System size"
                                component={TextFieldFormik}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      /kWp
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Area of one panel:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="panel_area"
                                label="Area of one panel"
                                component={TextFieldFormik}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      m<sup>2</sup>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Panel Efficiency:</div>
                            <div className="w-full">
                              <Field
                                type="number"
                                name="panel_efficiency"
                                label="Panel Efficiency"
                                component={TextFieldFormik}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          style={
                            isMobile
                              ? {
                                  flex: '1 1 0%',
                                  flexDirection: 'column',
                                  height: '100vh',
                                  width: '100vw'
                                }
                              : { flex: '1 1 0%' }
                          }
                          className="flex-1"
                        >
                          <div
                            className="w-full h-full pr-20"
                            style={{ paddingRight: '2rem' }}
                          >
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: GOOGLE_BROWSER_KEY }}
                              defaultCenter={this.state.center}
                              defaultZoom={this.state.zoom}
                            >
                              {this.renderMarker()}
                            </GoogleMapReact>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="flex flex-row items-center my-8 pr-20">
                          <div style={{ width: '16.77%' }}>Users</div>
                          <div className="w-full">
                            <Field
                              name="users"
                              component={UsersSelect}
                              isMulti
                              accountID={this.props.match.params.acc_id}
                              locationID={this.props.match.params.loc_id}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row my-8">
                          <Button
                            variant="contained"
                            className={classes.submitButton}
                            onClick={() => {
                              getSubmitFuncFormik(formikRenderProps)();
                            }}
                            isLoading={isSubmittingFormik(formikRenderProps)}
                            disabled={!canSubmitFormik(formikRenderProps)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.submitButton}
                            onClick={() => {
                              const submitFormFn = getSubmitFuncFormik(
                                formikRenderProps
                              );
                              submitFormFn().then(() => {
                                this.props.history.goBack();
                              });
                            }}
                            isLoading={isSubmittingFormik(formikRenderProps)}
                            disabled={!canSubmitFormik(formikRenderProps)}
                          >
                            Save & Exit
                          </Button>
                          <Button
                            className={classes.cancelButton}
                            component={Link}
                            to={
                              this.props.location.state
                                ? '/manage_locations'
                                : '/manage_account'
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>

                      <Dialog
                        open={this.state.open}
                        onClose={this.closeDialogPrompt}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'Are you sure to delete this location?'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            This process is irreversible
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.closeDialogPrompt}
                            color="primary"
                          >
                            No
                          </Button>
                          <Button
                            // onClick={this.confirmDeleteLocation}
                            onClick={this.openPasswordPrompt}
                            color="primary"
                            autoFocus
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <Dialog
                        open={this.state.passwordPrompt}
                        onClose={this.closePasswordPrompt}
                      >
                        <DialogTitle>Password Verification</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Input your password below to confirm the removing of
                            user's location
                          </DialogContentText>
                          <Formik
                            onSubmit={(values, { setSubmitting }) => {
                              firebaseService
                                .signInWithEmailAndPassword(
                                  this.props.email,
                                  values.password
                                )
                                .then(res => {
                                  this.confirmDeleteLocation(
                                    this.state.data.location_id
                                  );
                                })
                                .then(() => setSubmitting(false))
                                .catch(err => {
                                  setSubmitting(false);
                                  // console.log(err.message);
                                  this.props.showErrorMessage(err.message);
                                });
                            }}
                            isInitialValid
                          >
                            {formikRenderProps => (
                              <Form>
                                <Field
                                  type="password"
                                  name="password"
                                  label="Password"
                                  component={TextFieldFormik}
                                  fullWidth
                                />
                                <SubmitButtonFormik
                                  formikRenderProps={formikRenderProps}
                                >
                                  Submit
                                </SubmitButtonFormik>
                              </Form>
                            )}
                          </Formik>
                        </DialogContent>
                      </Dialog>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage
    },
    dispatch
  );
}

function mapStateToProps({ locations, auth }) {
  const { avatar } = locations;
  const { success, message } = avatar;

  const { email } = auth.user.data;
  const { role } = auth.user;

  return {
    avatar: {
      success,
      message
    },
    email,
    role
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(LocationPage)
);
