import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import {
  getZones,
  getZoneReadings,
  getZoneDevices,
  getZoneDeviceReadings,
  getZoneChartDataByFilter,
} from './utils';
import ZoneCard from './ZoneCard';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

class ZoneTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: {},
      currentExpandedPanel: null,
      filter: 'monthly',
      open: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getLocationZones();
  }

  getLocationZones = () => {
    let locationId = this.props.match.params.locationId;
    getZones(locationId).onSnapshot((zoneDocs) => {
      let zoneObj = this.state.zones;
      zoneDocs.forEach((zoneDoc) => {
        getZoneReadings(locationId, zoneDoc.id).onSnapshot((docs) => {
          let zoneReadings = docs.docs.length > 0 ? docs.docs[0].data() : {}; //always return 1 value ONLY - sorted by latest timestamp
          zoneObj[zoneDoc.id] = {
            ...zoneDoc.data(),
            ...zoneReadings,
            devices: {},
            chartData: {},
          };
          this.setState({ zones: zoneObj });
          this.getLocationDevices(locationId, zoneDoc.id);
        });
      });
    });
  };

  getLocationDevices = (_locationId, _zoneId) => {
    this.getZoneChartData(_locationId, _zoneId, this.state.filter);

    getZoneDevices(_locationId, _zoneId).onSnapshot((deviceDocs) => {
      let zoneObj = this.state.zones;
      deviceDocs.forEach((deviceDoc) => {
        getZoneDeviceReadings(_locationId, _zoneId, deviceDoc.id).onSnapshot(
          (docs) => {
            let deviceReadings =
              docs.docs.length > 0 ? docs.docs[0].data() : {}; //always return 1 value ONLY - sorted by latest timestamp
            zoneObj[_zoneId].devices[deviceDoc.id] = {
              ...deviceDoc.data(),
              ...deviceReadings,
            };

            this.setState({ zones: zoneObj });
          }
        );
      });
    });
  };

  getZoneChartData = (_locationId, _zoneId, _filter) => {
    this.setState({ isLoading: true });

    getZoneChartDataByFilter(_locationId, _zoneId, _filter).onSnapshot(
      (dataLogs) => {
        if (dataLogs.docs.length === 0) {
          this.setState({ isLoading: false });
        } else {
          let zoneObj = this.state.zones;
          let chartPromise = new Promise((resolve, reject) => {
            dataLogs.docs.forEach((dataLog, index, array) => {
              zoneObj[_zoneId].chartData[dataLog.id] = dataLog.data();
              this.setState({ zones: zoneObj });
              setTimeout(() => {
                if (index === array.length - 1) {
                  resolve();
                }
              }, 500);
            });
          });
          chartPromise.finally(() => this.setState({ isLoading: false }));
        }
      }
    );
  };

  handleExpand = (_panel) =>
    this.setState({
      currentExpandedPanel:
        this.state.currentExpandedPanel === _panel ? false : _panel,
    });

  handleChangeFilter = (event) => {
    this.setState({ filter: event.target.value }, this.getLocationZones());
  };

  handleCloseFilter = () => {
    this.setState({ open: false });
  };

  handleOpenFilter = () => {
    this.setState({ open: true });
  };

  render() {
    const { currentExpandedPanel, zones, open, filter, isLoading } = this.state;
    return (
      <div>
        <Grid container>
          {/* empty grid to take up spaces */}
          <Grid item lg={4} md={4} xs={4}></Grid>
          <Grid item lg={4} md={4} xs={4}>
            <Typography
              style={{ fontSize: 24 }}
              className="flex justify-center"
            >
              Zone Overview
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} xs={4} className="flex justify-end">
            <Select
              disableUnderline
              open={open}
              onClose={this.handleCloseFilter}
              onOpen={this.handleOpenFilter}
              value={filter}
              onChange={this.handleChangeFilter}
            >
              <MenuItem value={'monthly'}>This Year</MenuItem>
              <MenuItem value={'daily'}>This Month</MenuItem>
              <MenuItem value={'half_hourly'}>Today</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {Object.keys(zones).map((zone, index) => {
          return (
            <ZoneCard
              data={zones[zone]}
              index={index}
              isExpanded={currentExpandedPanel === `panel${index}`}
              onExpand={this.handleExpand}
              filter={filter}
              isLoading={isLoading}
            />
          );
        })}
      </div>
    );
  }
}

export default withRouter(ZoneTab);
