import * as Actions from '../actions';
import * as avatarActions from 'store/actions/pylon'

const initialState = {
    role: '',
    data: {
        'displayName': '',
        'photoURL'   : '',
        'email'      : '',
        shortcuts    : [
            'calendar',
            'mail',
            'contacts',
            'todo'
        ]
    },
    isAuthenticated: false
};

const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                ...action.payload
            };
        }

        case Actions.SET_GUEST_USER_DATA: {
            return {
                ...initialState,
                role: 'guest'
            };
        }

        case avatarActions.UPLOAD_AVATAR: {
            // Overrite image uri!.
            return {
                ...state,
                data: {
                    ...state.data,
                    photoURL: action.payload
                }
            }
        }

        case avatarActions.REMOVE_AVATAR: {
            // Overrite image uri!.
            return {
                ...state,
                data: {
                    ...state.data,
                    photoURL: ''
                }
            }
        }

        case Actions.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }

        case Actions.USER_LOGGED_OUT:
        {
            return {
                ...initialState,
                role: 'guest'
            };
        }

        default:
        {
            return state
        }
    }
};

export default user;
