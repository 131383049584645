import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse/index";
import { LoginConfig } from "main/content/login/LoginConfig";
import { DashboardConfig } from "main/content/dashboard/DashboardConfig";
import { ProfileConfig } from "main/content/profile/ProfileConfig";
import { SupportConfig } from "main/content/support/SupportConfig";
import { LocationConfig } from "main/content/location/LocationConfig";
import { AccountsConfig } from "main/content/accounts/AccountsConfig";
import { UsersConfig } from "main/content/users/UsersConfig";
import { LocationsConfig } from "main/content/locations/LocationsConfig";

const routeConfigs = [
  LoginConfig,
  DashboardConfig,
  ProfileConfig,
  SupportConfig,
  LocationConfig,
  AccountsConfig,
  UsersConfig,
  LocationsConfig
];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs)
  // {
  //     path     : '/',
  //     component: () => <Redirect to="/dashboard"/>
  // }
];
