import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import UploadedFile from "./UploadedFile";

const UploadInput = ({
  label,
  file,
  onChange,
  error,
  disabled,
  helperText,
  InputProps,
  inputProps,
}) => {
  return (
    <>
      <TextField
        fullWidth
        type="file"
        variant="outlined"
        error={error}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
          htmlFor: "upload-input",
        }}
        label={label}
        helperText={helperText}
        value=""
        onChange={onChange}
        inputProps={inputProps}
        InputProps={InputProps}
      />
      {file && <UploadedFile uploadUrl={file} onChange={onChange} />}
    </>
  );
};

UploadInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  file: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
};

UploadInput.defaultProps = {
  label: "",
  value: "",
  file: "",
  onChange: () => {},
  error: false,
  disabled: false,
  helperText: "",
  InputProps: {},
  inputProps: {},
};

export default UploadInput;
