import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Typography,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import { getAllLocations, getALocation, getLocationsForAcc } from './api';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100vw',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    display: 'flex',
    marginLeft: '6.4rem',
    alignItems: 'flex-end'
  },
  breadcrumbText: {
    color: '#363636 !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  titleText: {
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem'
  },
  textLastEdited: {
    color: '#898989'
  },
  table: {
    minWidth: 350
    // overflowX: "auto"
  },
  avatar: {
    margin: 10
  }
});

class ManageLocationsContent extends Component {
  state = {
    locations: [],
    isLoading: false,
    rowsPerPage: 5,
    page: 0,
    editedLocations: []
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    if (this.props.role == 99) {
      getAllLocations()
        .then(res =>
          this.setState({
            locations: res.data,
            isLoading: false,
            editedLocations: _.slice(
              res.data,
              this.state.page,
              this.state.rowsPerPage
            )
          })
        )
        .catch(err => console.log(err));
    } else if (this.props.role == 30) {
      let allAssignedLocations = [];
      let myAssignedLocations = this.props.assigned_locations;
      let myAssignedAccounts = this.props.assigned_accounts;

      async function batchGetMyAssignedLocation(locations) {
        for (let locationIndex in locations) {
          const resolved = await getALocation(locations[locationIndex]);
          allAssignedLocations.push(resolved.data);
        }
      }

      async function batchGetLocationsForAssignedAccounts(accounts) {
        for (let accountIndex in accounts) {
          const resolved = await getLocationsForAcc(accounts[accountIndex]);
          allAssignedLocations.push(...resolved.data);
        }
      }

      batchGetMyAssignedLocation(myAssignedLocations)
        .then(() => batchGetLocationsForAssignedAccounts(myAssignedAccounts))
        .then(() => {
          let uniqueLocationsArray = _.uniqBy(
            allAssignedLocations,
            'location_id'
          );
          this.setState({
            isLoading: false,
            locations: uniqueLocationsArray,
            editedLocations: _.slice(
              uniqueLocationsArray,
              this.state.page,
              this.state.rowsPerPage
            )
          });
        })
        .catch(err => console.log(err));
    } else if (this.props.role == 20) {
      let locArr = [];
      let myAssignedLocation = this.props.assigned_locations;
      getALocation(myAssignedLocation[0])
        // .then(res => console.log(res.data))
        .then(res => {
          locArr.push(res.data);
          this.setState({
            isLoading: false,
            editedLocations: _.slice(
              locArr,
              this.state.page,
              this.state.rowsPerPage
            ),
            locations: locArr
          });
        })
        .catch(err => console.log(err));
    }
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    });
    page == 0
      ? this.setState({
          editedLocations: _.slice(
            this.state.locations,
            page,
            this.state.rowsPerPage
          )
        })
      : this.setState({
          editedLocations: _.slice(
            this.state.locations,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          )
        });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      editedLocations: _.slice(
        this.state.locations,
        this.state.page,
        event.target.value
      )
    });
  };

  render() {
    const { classes } = this.props;
    const { locations, isLoading, editedLocations } = this.state;

    return (
      <div>
        {isLoading ? (
          <div className="flex justify-center mt-64">
            <CircularProgress size={48} />
          </div>
        ) : (
          <div className={classes.root}>
            {/* <div className="flex ml-64 items-end"> */}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>Location Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Assigned Account</TableCell>
                  <TableCell>Last Activity</TableCell>
                  <TableCell>Date Created</TableCell>
                  {/* <TableCell /> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {editedLocations.map(
                  ({
                    name,
                    location_id,
                    status,
                    account_id,
                    updated_on,
                    created_on
                  }) => (
                    <TableRow key={location_id} hover={true}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{status ? 'Active' : 'Inactive'}</TableCell>
                      <TableCell>{account_id}</TableCell>
                      <TableCell>
                        {updated_on
                          ? moment
                              .unix(updated_on._seconds)
                              .format('DD MMMM YYYY @ HH:mm')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {created_on
                          ? moment
                              .unix(created_on._seconds)
                              .format('DD MMMM YYYY @ HH:mm')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          // to={`/manage_account/${account_id}/location/${location_id}`}
                          to={{
                            pathname: `/manage_account/${account_id}/location/${location_id}`,
                            state: { fromManageLocation: true }
                          }}
                        >
                          <Icon>edit_icon</Icon>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={locations.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Table>
            {/* </div> */}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(ManageLocationsContent)
);
