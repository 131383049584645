import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import moment, { fn } from 'moment';
import _ from 'lodash';
import { getAllAccount, getAnAccount } from '../api';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  breadcrumbText: {
    color: '#363636 !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  titleText: {
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem'
  },
  textLastEdited: {
    color: '#898989'
  },
  table: {
    minWidth: 700,
    overflowX: 'auto'
  }
});

class AccountTab extends Component {
  state = {
    accounts: [],
    isLoading: false,
    page: 0,
    rowsPerPage: 5,
    editedAccounts: []
  };
  componentDidMount() {
    this.setState({
      isLoading: true
    });

    if (this.props.role == 99) {
      setTimeout(() => {
        getAllAccount()
          .then(res => {
            this.setState({
              accounts: res.data,
              isLoading: false,
              editedAccounts: _.slice(
                res.data,
                this.state.page,
                this.state.rowsPerPage
              )
            });
          })
          .catch(err => {
            console.log(err);
          });
      }, 1000);
    } else if (this.props.role == 30) {
      async function batchGetMultipleAccounts(accounts) {
        let allAssignedAccounts = [];
        for (let x in accounts) {
          const resolved = await getAnAccount(accounts[x]);
          allAssignedAccounts.push({
            account_users: resolved.data.account.users,
            accountdata: resolved.data.account,
            locations: resolved.data.num_locations
          });
        }
        return allAssignedAccounts;
      }
      batchGetMultipleAccounts(this.props.assigned_accounts).then(val => {
        this.setState({
          accounts: val,
          isLoading: false,
          editedAccounts: _.slice(val, this.state.page, this.state.rowsPerPage)
        });
      });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    });
    page == 0
      ? this.setState({
          editedAccounts: _.slice(
            this.state.accounts,
            page,
            this.state.rowsPerPage
          )
        })
      : this.setState({
          editedAccounts: _.slice(
            this.state.accounts,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          )
        });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      editedAccounts: _.slice(
        this.state.accounts,
        this.state.page,
        event.target.value
      )
    });
  };

  render() {
    const { classes } = this.props;
    const { accounts, isLoading, editedAccounts } = this.state;

    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex mt-40 ml-64">
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>
              Manage Accounts
            </Typography>
          </div>
          <div className="flex mt-16 ml-64">
            <Typography className={classes.titleText} variant="h5">
              Accounts
            </Typography>
            {this.props.role != 30 ? (
              <Typography
                to="/manage_account/new"
                component={Link}
                className={classes.linkText}
              >
                + Add New Account
              </Typography>
            ) : (
              <div></div>
            )}
          </div>
          {isLoading ? (
            <div className="flex justify-center mt-64">
              <CircularProgress size={48} />
            </div>
          ) : (
            <div className="flex mx-64 mb-64 pb-64">
              {/* <Paper className={classes.root}> */}
              <div className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Locations</TableCell>
                      <TableCell>Users</TableCell>
                      <TableCell>Contact Person</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Last Activity</TableCell>
                      <TableCell>Date Created</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editedAccounts.map(account => (
                      <TableRow
                        key={account.accountdata.account_id}
                        hover={true}
                      >
                        <TableCell>{account.accountdata.name}</TableCell>
                        <TableCell>{account.locations}</TableCell>
                        <TableCell>{account.account_users.length}</TableCell>
                        <TableCell>
                          {account.accountdata.contact_person}
                        </TableCell>
                        <TableCell>
                          {account.accountdata.contact_number}
                        </TableCell>
                        <TableCell>
                          {account.accountdata.updated_on
                            ? moment
                                .unix(account.accountdata.updated_on._seconds)
                                .format('DD MMMM YYYY @ HH:mm')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {moment
                            .unix(account.accountdata.created_on._seconds)
                            .format('DD MMMM YYYY @ HH:mm')}
                        </TableCell>

                        <TableCell>
                          <Button
                            component={Link}
                            to={`/manage_account/${account.accountdata.account_id}`}
                          >
                            <Icon>edit_icon</Icon>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={accounts.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page'
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page'
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Table>
              </div>
              {/* </Paper> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(AccountTab)
);
