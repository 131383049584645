import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik
} from '@massiveinfinity/components/Form/FormikInputs';
import { Button, Typography, InputAdornment } from '@material-ui/core';
import { sectorSelect, timezoneSelect } from './const';
import { withStyles } from '@material-ui/core/styles/index';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AccountsSelect from './AccountsSelect';
import { addLocationForAnAccount } from './api';
import validationSchema from './validationSchema';
import validationSchemaForNonMaster from './validationSchemaForNonMaster';

const styles = theme => ({
  submitButton: {
    backgroundColor: '#68f72a',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase'
  },
  cancelButton: {
    backgroundColor: '#5d5e5c',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase',
    color: '#cfd0d1'
  }
});

class AddLocationContent extends Component {
  state = {
    pre_filled_location_id: ''
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.pre_filled_location_id !== nextState.pre_filled_location_id
    ) {
      return true;
    }
  }

  render() {
    const { classes, showErrorMessage, showSuccessMessage } = this.props;
    return (
      <div className="mt-16 ml-64">
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            addLocationForAnAccount(values.account, {
              ...values,
              location_id:
                this.state.pre_filled_location_id + values.location_id
            })
              .then(res => {
                showSuccessMessage('Location successfully added');
                this.props.history.push({
                  pathname: '/manage_locations'
                });
              })
              .catch(err => {
                showErrorMessage(err.response.data.message);
              });
            setSubmitting(false);
          }}
          validationSchema={
            this.props.role == 99
              ? validationSchema
              : validationSchemaForNonMaster
          }
          initialValues={
            this.props.role == 30
              ? {
                  timezone: 'UTC+8',
                  account: this.props.assigned_accounts[0]
                }
              : {
                  timezone: 'UTC+8'
                }
          }
        >
          {formikRenderProps => (
            <Form>
              {formikRenderProps.values.account &&
                this.setState({
                  pre_filled_location_id: formikRenderProps.values.account + '_'
                })}
              <div className="flex flex-row">
                <div className="w-full">
                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Account:</div>
                    <div className="w-full">
                      {this.props.role == 30 ? (
                        <Field
                          name="account"
                          component={SelectFormik}
                          options={[
                            {
                              label: this.props.assigned_accounts[0],
                              value: this.props.assigned_accounts[0]
                            }
                          ]}
                          isDisabled
                        />
                      ) : (
                        <Field name="account" component={AccountsSelect} />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Location name:</div>
                    <div className="w-full">
                      <Field
                        name="name"
                        label="Location Name"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Location ID:</div>
                    <div className="w-full flex">
                      <Field
                        name="pre_filled_location_id"
                        component={TextFieldFormik}
                        disabled
                        fullWidth
                        value={this.state.pre_filled_location_id}
                      />
                      <Field
                        name="location_id"
                        label="Location ID"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Sector:</div>
                    <div className="w-full">
                      <Field
                        name="sector"
                        label="Sector"
                        component={SelectFormik}
                        options={sectorSelect}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Address:</div>
                    <div className="w-full">
                      <Field
                        name="address"
                        label="Address"
                        multiline
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Timezone:</div>
                    <div className="w-full">
                      <Field
                        name="timezone"
                        label="Time zone"
                        component={SelectFormik}
                        options={timezoneSelect}
                        isDisabled
                        fullWidth
                      />
                    </div>
                  </div>

                  {this.props.role == 99 ? (
                    <div className="flex flex-row items-center my-8">
                      <div className="w-2/5">Invested Amount:</div>
                      <div className="w-full">
                        <Field
                          type="number"
                          name="invested_amt"
                          label="SGD $"
                          component={TextFieldFormik}
                          fullWidth
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Tariff:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="tariff"
                        label="SGD $"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/kWh</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Brand:</div>
                    <div className="w-full">
                      <Field
                        name="panel_brand"
                        label="Panel brand"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Count:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_count"
                        label="Panel count"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Rating:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_rating"
                        label="Panel rating"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/W</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">System Size:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="system_size"
                        label="System size"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/kWp</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Area of one panel:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_area"
                        label="Area of one panel"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              m<sup>2</sup>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Efficiency:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_efficiency"
                        label="Panel Efficiency"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row my-8">
                    <SubmitButtonFormik formikRenderProps={formikRenderProps}>
                      Submit
                    </SubmitButtonFormik>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts } = data;
  return {
    role,
    uid,
    assigned_accounts
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AddLocationContent)
);
