import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PylonToolbarGeneratedEnergy } from '@pylon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const propTypes = {};

const defaultProps = {};

const styles = theme => ({});

class PylonToolbarData extends Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { toolbar, user_role } = this.props;
    const { energy } = toolbar;
    const { width } = this.state;
    const isMobile = width <= 500;

    if (isMobile) {
      return (
        <div className="flex items-center">
          <div className="flex flex-col">
            {energy.data.totalEnergyGeneratedToday != 'NaN' && user_role == 30 && (
              <div>
                <Typography component="span" className="uppercase text-6 mr-4">
                  Energy Generated today
                </Typography>

                <PylonToolbarGeneratedEnergy
                  isLoading={energy.isLoading}
                  success={energy.success}
                  data={energy.data.totalEnergyGeneratedToday}
                  dataUnits="MWh"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col items-center ml-164">
            {energy.data.totalPowerGenerated != 'NaN' && user_role == 30 && (
              <div>
                <Typography component="span" className="uppercase text-6 mr-4">
                  Power Generation
                </Typography>

                <PylonToolbarGeneratedEnergy
                  isLoading={energy.isLoading}
                  success={energy.success}
                  data={energy.data.totalPowerGenerated}
                  dataStyle="font-200"
                  dataUnits="kW"
                />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <div className="flex items-center mr-16">
            {energy.data.totalEnergyGeneratedToday != 'NaN' && user_role == 30 && (
              <div>
                <Typography component="span" className="uppercase text-11 mr-4">
                  Energy Generated today
                </Typography>

                <PylonToolbarGeneratedEnergy
                  isLoading={energy.isLoading}
                  success={energy.success}
                  data={energy.data.totalEnergyGeneratedToday}
                  dataUnits="MWh"
                />
              </div>
            )}
          </div>

          <div className="flex items-center ml-16">
            {energy.data.totalPowerGenerated != 'NaN' && user_role == 30 && (
              <div>
                <Typography component="span" className="uppercase text-11 mr-4">
                  Power Generation
                </Typography>

                <PylonToolbarGeneratedEnergy
                  isLoading={energy.isLoading}
                  success={energy.success}
                  data={energy.data.totalPowerGenerated}
                  dataStyle="font-200"
                  dataUnits="kW"
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

PylonToolbarData.propTypes = propTypes;
PylonToolbarData.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ pylon, auth }) {
  const { toolbar } = pylon;
  const { user } = auth;

  return {
    toolbar: toolbar,
    user_role: user.role
  };
}

export default withStyles(styles, {
  withTheme: true
})(connect(mapStateToProps, mapDispatchToProps)(PylonToolbarData));
