export const fuseAccountAdminNavigationConfig = [
  {
    id: "accounts",
    title: "ACCOUNTS",
    type: "collapse",
    icon: "group",
    open: true,
    fixed: true,
    children: [
      {
        id: "manage-accounts",
        title: "Manage accounts",
        type: "item",
        url: "/manage_account",
        exact: true
      }
    ]
  },
  {
    id: "pylons",
    title: "PYLONS",
    type: "collapse",
    icon: "group",
    open: true,
    fixed: true,
    children: [
      {
        id: "add-pylon",
        title: "Add a Location",
        type: "item",
        url: "/manage_locations/add_location",
        exact: true
      },
      {
        id: "manage-pylons",
        title: "Manage Locations",
        type: "item",
        url: "/manage_locations",
        exact: true
      }
    ]
  },
  {
    id: "users",
    title: "USERS & ACCESS",
    type: "collapse",
    icon: "group",
    open: true,
    fixed: true,
    children: [
      {
        id: "add-user",
        title: "Add user",
        type: "item",
        url: "/manage_users/new_user",
        exact: true
      },
      {
        id: "manage-users",
        title: "Manage users",
        type: "item",
        url: "/manage_users",
        exact: true
      }
    ]
  }
];
