import * as Actions from '../actions';

const initialState = {
  location: {
    success: false,
    message: '',
    data: {}
  },
  overview: {
    success: false,
    message: '',
    data: {}
  },
  chart: {
    data: null
  },
  metersId: {
    success: false,
    message: '',
    data: {}
  },
  alarmsAndAlerts: {
    success: false,
    message: '',
    data: {}
  }
};

const loc = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LOCATION: {
      return {
        ...state,
        location: {
          succes: true,
          data: action.payload
        }
      };
    }

    case Actions.ERROR_GET_LOCATION: {
      return {
        ...state,
        location: {
          success: false,
          message: 'An error has occurred'
        }
      };
    }

    case Actions.GET_OVERVIEW: {
      return {
        ...state,
        overview: {
          success: true,
          data: action.payload
        }
      };
    }

    case Actions.GET_CHART_OVERVIEW: {
      const chartData = action.payload;

      let formattedChartData = {
        x: [],
        energyGenerated: [],
        energyExported: [],
        totalEnergyGenerated: [],
        totalEnergyExported: []
      };

      chartData.forEach(row => {
        formattedChartData.x.push(row.timestamp);
        formattedChartData.totalEnergyGenerated.push(
          row.total_energy_generated
        );
        formattedChartData.totalEnergyExported.push(row.total_energy_exported);
        formattedChartData.energyGenerated.push(row.hh_energy_generated);
        formattedChartData.energyExported.push(row.hh_energy_exported);
      });

      return {
        ...state,
        chart: {
          data: formattedChartData
        }
      };
    }

    case Actions.ERROR_GET_OVERVIEW: {
      return {
        ...state,
        overview: {
          success: false,
          message: 'An error has occurred'
        }
      };
    }

    case Actions.GET_METER_ID: {
      return {
        ...state,
        metersId: {
          success: true,
          data: action.payload
        }
      };
    }

    case Actions.ERROR_GET_METER_ID: {
      return {
        ...state,
        metersId: {
          success: false,
          message: 'An error has occurred'
        }
      };
    }

    case Actions.GET_ALERTS_AND_ALARMS_INITIAL_QUERY: {
      return {
        ...state,
        alarmsAndAlerts: {
          success: true,
          data: action.payload
        }
      };
    }

    case Actions.GET_ALERTS_AND_ALARMS_NEXT_PAGE: {
      return {
        ...state,
        alarmsAndAlerts: {
          success: true,
          data: action.payload
        }
      };
    }

    case Actions.GET_ALERTS_AND_ALARMS_PREV_PAGE: {
      return {
        ...state,
        alarmsAndAlerts: {
          success: true,
          data: action.payload
        }
      };
    }

    case Actions.ERROR_GET_ALERTS_AND_ALARMS: {
      return {
        ...state,
        alarmsAndAlerts: {
          success: false,
          message: 'An error has occured'
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default loc;
