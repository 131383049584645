import * as Actions from "../../actions/pylon/index";

const initialState = {
  success: false,
  message: "",
  data: ""
};

const avatar = function(state = initialState, action) {
  switch (action.type) {
    case Actions.UPLOAD_AVATAR: {
      return {
        ...state,
        success: true,
        message: "Successfully uploaded new image",
        data: action.payload
      };
    }

    case Actions.REMOVE_AVATAR: {
      return {
        ...state,
        success: true,
        message: "Successfully removed image",
        data: ""
      };
    }

    case Actions.ERROR_AVATAR: {
      return {
        ...state,
        success: false,
        message: action.payload,
        data: ""
      };
    }

    case Actions.CLEAR_STATE_AVATAR: {
      return {
        success: false,
        message: ""
      };
    }

    default: {
      return state;
    }
  }
};

export default avatar;
