import * as Actions from '../actions';
import * as avatarActions from 'store/actions/pylon';
import avatar from '../../../../../store/reducers/pylon/avatar.reducer';

const initialState = {
    success: false,
    message: '',
    data: undefined
};

const user = function(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USER_PROFILE: {
            return {
                ...state,
                success: true,
                data: action.payload
            };
        }

        case avatarActions.UPLOAD_AVATAR: {
            // Overrite image uri!.
            return {
                ...state,
                data: {
                    ...state.data,
                    img_uri: action.payload
                }
            }
        }

        case avatarActions.REMOVE_AVATAR: {
            // Overrite image uri!.
            return {
                ...state,
                data: {
                    ...state.data,
                    img_uri: ''
                }
            }
        }

        case Actions.ERROR_USER_PROFILE: {
            return {
                ...state,
                success: false,
                message: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export default user;