import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import ZoneGraph from './ZoneGraph';
import IconButton from '@material-ui/core/IconButton';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import DropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  expansionPanel: {
    boxShadow: 'none',
    border: '2px solid #DBDBDD',
  },
  headerLabel: {
    fontSize: 18,
    fontWeight: 200,
    color: '#898989',
    marginRight: 10,
    textTransform: 'uppercase',
  },
  valueStyle: {
    fontSize: 50,
  },
  readingLabel: {
    textTransform: 'uppercase',
    fontSize: 16,
    marginRight: 20,
  },
  readingUnit: {
    fontSize: 18,
    fontWeight: 200,
    color: '#898989',
    marginLeft: 8,
  },
  subValueStyle: {
    fontSize: 30,
  },
  subReadingUnit: {
    fontSize: 12,
    fontWeight: 200,
    color: '#898989',
    marginLeft: 8,
  },
});

class ControlledExpansionPanels extends Component {
  READING_TYPE = {
    ELECTRICITY: 'ELECTRICITY',
    WATER: 'WATER',
    CO2: 'CO2',
    GEN: 'GEN',
    ELECTRICITY_PSM: 'ELECTRICITY_PSM',
  };

  render() {
    const {
      classes,
      data,
      isExpanded,
      onExpand,
      index,
      filter,
      isLoading,
    } = this.props;
    const {
      zone_label,
      energy_consumed,
      water,
      carbon,
      gen,
      energy_per_unit_area,
      devices,
      chartData,
    } = data;

    let getBarColor = (_type) => {
      let color;
      switch (_type) {
        case this.READING_TYPE.ELECTRICITY:
          color = '165,237,223';
          break;
        case this.READING_TYPE.WATER:
          color = '134,173,225';
          break;
        case this.READING_TYPE.CO2:
          color = '250,224,156';
          break;
        case this.READING_TYPE.GEN:
          color = '170,237,169';
          break;
        default:
          break;
      }
      return color;
    };

    let roundUpValue = (_value) => {
      return _.isNumber(_value) ? (_value * 1).toFixed(2) : '-';
    };

    let renderUnit = (_label, _roundedValue) => {
      if (_label === this.READING_TYPE.WATER) {
        return 'Cu M';
      } else if (_label === this.READING_TYPE.CO2) {
        return 'kg';
      } else if (_label === this.READING_TYPE.ELECTRICITY_PSM) {
        return 'kwH/m2';
      } else {
        return _roundedValue.toString().length < 10 ? 'kWh' : 'MWh';
      }
    };

    let rendeReading = (
      _isDevice,
      _label,
      _value,
      _subLabel,
      _subValue,
      _chartData
    ) => {
      const isWithSubData = _subLabel === this.READING_TYPE.ELECTRICITY_PSM;

      let roundedValue = roundUpValue(_value);
      let roundedSubValue = roundUpValue(_subValue);

      let rawData = _chartData
        ? Object.keys(_chartData).map((key) => {
            return _chartData[key];
          })
        : [];

      return (
        <Grid item xs={12} md={4} lg={3}>
          <Grid container alignItems="baseline" justify="flex-end">
            <Typography className={classes.readingLabel}>{_label}</Typography>
            <Typography
              className={_isDevice ? classes.subValueStyle : classes.valueStyle}
            >
              {roundedValue.toString().length < 10
                ? roundedValue
                : ((roundedValue * 1) / 1000).toFixed(2)}
            </Typography>
            <Typography className={classes.readingUnit}>
              {renderUnit(_label, roundedValue)}
            </Typography>
          </Grid>
          <Grid container alignItems="baseline" justify="flex-end">
            <Typography
              className={classes.subValueStyle}
              style={
                isWithSubData
                  ? { visibility: 'inherit' }
                  : { visibility: 'hidden' }
              }
            >
              {roundedSubValue.toString().length < 10
                ? roundedSubValue
                : ((roundedSubValue * 1) / 1000).toFixed(2)}
            </Typography>
            <Typography
              className={classes.subReadingUnit}
              style={
                isWithSubData
                  ? { visibility: 'inherit' }
                  : { visibility: 'hidden' }
              }
            >
              {renderUnit(_subLabel, roundedSubValue)}
            </Typography>
          </Grid>
          {isExpanded && _chartData && (
            <ZoneGraph
              color={getBarColor(_label)}
              range={filter}
              rawData={rawData}
              filterKey={_label}
              isLoading={isLoading}
            />
          )}
        </Grid>
      );
    };

    let renderLabel = (_label) => {
      return _label && _.startCase(_label.replace(/_/g, ' '));
    };

    return (
      <div className={classes.root}>
        <ExpansionPanel
          className={classes.expansionPanel}
          expanded={isExpanded}
        >
          <ExpansionPanelSummary>
            <Grid container alignItems="flex-start" spacing={40}>
              <Grid item xs={12} md={4} lg={3} alignItems="baseline">
                <Grid container alignItems="baseline">
                  <Typography className={classes.headerLabel}>Zone:</Typography>
                  <Typography className={classes.valueStyle}>
                    {renderLabel(zone_label)}
                  </Typography>
                </Grid>
              </Grid>
              {rendeReading(
                false,
                this.READING_TYPE.ELECTRICITY,
                energy_consumed,
                this.READING_TYPE.ELECTRICITY_PSM,
                energy_per_unit_area,
                chartData
              )}
              {rendeReading(
                false,
                this.READING_TYPE.WATER,
                water,
                null,
                null,
                chartData
              )}
              {rendeReading(
                false,
                this.READING_TYPE.CO2,
                carbon,
                null,
                null,
                chartData
              )}
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={12}>
              {Object.keys(devices).length !== 0 && (
                <Typography className={classes.headerLabel}>
                  Devices:
                </Typography>
              )}
              {Object.keys(devices).map((deviceKey) => {
                const {
                  energy_consumed: deviceEnergyConsumed,
                  water: deviceWater,
                  carbon: deviceCarbon,
                  appliance,
                } = devices[deviceKey];

                let isDevice = true;

                return (
                  <Grid container>
                    <Grid item xs={12} md={4} lg={3} alignItems="baseline">
                      <Grid container alignItems="baseline">
                        <Typography className={classes.subValueStyle}>
                          {renderLabel(appliance)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {rendeReading(
                      isDevice,
                      this.READING_TYPE.ELECTRICITY,
                      deviceEnergyConsumed
                    )}
                    {rendeReading(
                      isDevice,
                      this.READING_TYPE.WATER,
                      deviceWater
                    )}
                    {rendeReading(
                      isDevice,
                      this.READING_TYPE.CO2,
                      deviceCarbon
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Grid container alignItems="center" justify="center">
          <Typography>
            {isExpanded ? 'Hide' : 'View'} Expanded Information
          </Typography>
          <IconButton onClick={() => onExpand(`panel${index}`)}>
            {isExpanded ? <DropUpIcon /> : <DropdownIcon />}
          </IconButton>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ControlledExpansionPanels);
