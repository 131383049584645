import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FusePageSimple } from "@fuse";
import ManageLocationHeader from "./ManageLocationComponent/ManageLocationHeader";
import ManageLocationContent from "./ManageLocationComponent/ManageLocationContent";

const styles = theme => ({
  toolBarStyle: {
    borderBottom: "0px"
  },
  contentStyle: {
    marginTop: "5rem",
    marginBottom: "10rem",
    marginRight: "3rem"
  }
});

class ManageLocations extends Component {
  render() {
    const { classes } = this.props;
    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
          toolbar: classes.toolBarStyle,
          content: classes.contentStyle
        }}
        className="min-h-72 h-72 sm:h-136 sm:min-h-136"
        contentToolbar={<ManageLocationHeader />}
        content={<ManageLocationContent />}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(ManageLocations)
);
