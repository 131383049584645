import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter, Link } from 'react-router-dom';
import { Button, Typography, Select, MenuItem, InputBase, CircularProgress } from '@material-ui/core';
import { PylonAvatar } from '@pylon';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import * as Actions from 'store/actions';
import * as profileActions from '../store/actions';
import Moment from 'react-moment';

// Custom implementation of OutlinedInput.
// This is for custom styling to fit design requirements.
const SqueezedInput = withStyles(theme => ({
    input: {
        minHeight: '1.1875em',
        minWidth: '150px',
        userSelect: 'none',
        padding: '8px 26px 8px 12px',
        border: '1px solid #ced4da',
        borderRadius: '0px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}))(InputBase);

const styles = theme => ({
    root: {},
    breadcrumbText: {
        color: '#363636 !important',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: '300'
    },
    textLastEdited: {
        color: '#898989'
    },
    formFieldRow: {
        margin: '8px 0'
    },
    formFieldText: {
        color: '#636363',
        fontSize: '1.6rem'
    },
    textSupportLink: {
        color: '#01c0ff !important',
        fontWeight: '300',
        fontStyle: 'italic',
        fontSize: '1.3rem'
    },
    button: {
        minWidth: '160px',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        fontWeight: '300',
        zIndex: '0',
        boxShadow: 'none',
        borderRadius: '0px',
        '&.primary': {
            backgroundColor: theme.action.primary,
            color: 'black'
        },
        '&.neutral': {
            backgroundColor: theme.action.neutral,
            color: 'white'
        }
    }
});

class ProfileTab extends Component {

    state = {
        width: window.innerWidth
    }
    onChangeAccountType = name => event => {

    };

    componentDidMount() {
        if (this.props.userId) {
            this.props.getUserProfile(this.props.userId);
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }


    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    isUserRoleSuperAdmin = () => {
        const { user } = this.props;
        console.log(user);

        if (!user) {
            return false;
        }

        if (user.user_role == undefined) {
            return false;
        }

        if (user.user_role != 99) {
            return false;
        }

        return true;
    };

    isUserRoleAccountAdmin = () => {
        const { user } = this.props;
        console.log(user);

        if (!user) {
            return false;
        }

        if (user.user_role == undefined) {
            return false;
        }

        if (user.user_role != 30) {
            return false;
        }

        return true;
    };

    isUserRoleLocationAdmin = () => {
        const { user } = this.props;
        console.log(user);

        if (!user) {
            return false;
        }

        if (user.user_role == undefined) {
            return false;
        }

        if (user.user_role != 20) {
            return false;
        }

        return true;
    };

    isUserRoleNewAccount = () => {
        const { user } = this.props;
        console.log(user);

        if (!user) {
            return false;
        }

        if (user.user_role == undefined) {
            return false;
        }

        if (user.user_role != 0) {
            return false;
        }

        return true;
    };

    render() {
        const { classes, user, userId } = this.props;
        const { width } = this.state;
        const isMobile = width <= 500;

        let isOwnProfile = true;
        if (this.props.location.state) {
            isOwnProfile = this.props.location.state.isOwnProfile;
        }

        // Flag here to just hide fields that are currently not needed.
        // Not sure if it'll be needed in the future.
        const hideUnusedFields = true;

        console.log(user);

        return (
            <div className="w-full">
                <div className="flex flex-col">

                    {
                        this.isUserRoleSuperAdmin() &&
                        (
                            <div className="flex mt-8">
                                <Typography
                                    to="/dashboard"
                                    component={Link}
                                    className={classes.breadcrumbText}
                                >
                                    Dashboard
                                </Typography>
                                <p>&nbsp;>&nbsp;</p>
                                <Typography
                                    to="/dashboard"
                                    component={Link}
                                    className={classes.breadcrumbText}
                                >
                                    Manage Users
                                </Typography>
                                <p>&nbsp;>&nbsp;</p>
                                <Typography className={classes.breadcrumbText}>
                                    User
                                </Typography>
                            </div>
                        )
                    }

                    <div className="flex flex-row items-center mt-4">
                        <Typography
                            className="uppercase flex text-24"
                        >
                            User Profile
                        </Typography>

                        {
                            user === undefined || user.updated_on === undefined
                                ?
                                (
                                    <div></div>
                                )
                                :
                                (
                                    <Typography
                                        className={classNames(classes.textLastEdited, "flex text-11 italic ml-20")}
                                    >
                                        Last edited on&nbsp;
                                        <Moment
                                            format="D MMM YYYY, HH:mm:ss"
                                        >
                                            {user.updated_on != undefined || user.updated_on != null ? user.updated_on._seconds * 1000 : user.created_on._seconds * 1000}
                                        </Moment>
                                    </Typography>
                                )
                        }
                    </div>

                    {
                        user === undefined
                            ?
                            (
                                <div className="flex flex-col items-center mt-64">
                                    <CircularProgress
                                        color="primary"
                                    />
                                </div>
                            )
                            :
                            (
                                <div className={isMobile ? "w-full h-full flex flex-1 flex-col items-start mt-28 mb-28" : "w-full h-full flex flex-1 flex-row items-start mt-28 mb-28"}>

                                    <div className="flex">
                                        <PylonAvatar
                                            alt=""
                                            src={user.img_uri}
                                            name={user.name}
                                            userId={userId}
                                        />
                                    </div>

                                    <div className={isMobile ? "flex flex-1 flex-col h-full mt-40" : "flex flex-1 flex-col h-full ml-40"}>

                                        {
                                            !hideUnusedFields && (
                                                <div className={classNames(classes.formFieldRow, "flex flex-row mt-0")}>
                                                    <div className="flex flex-1">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            UserID:
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-1">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            Jesus
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className={classNames(classes.formFieldRow, "flex flex-row mt-0")}>
                                            <div className="min-w-1/3">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    Name:
                                                </Typography>
                                            </div>
                                            <div className="flex flex-grow">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    {user.name}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className={classNames(classes.formFieldRow, "flex flex-row items-center")}>
                                            <div className="min-w-1/3">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    Role:
                                                </Typography>
                                            </div>
                                            <div className="flex flex-1">
                                                <Select
                                                    value={user.user_role}
                                                    onChange={this.onChangeAccountType('acct_type')}
                                                    input={
                                                        <SqueezedInput />
                                                    }
                                                    inputProps={{
                                                        name: 'acct_type',
                                                        id: 'acct_type-simple',
                                                    }}
                                                    disabled
                                                >
                                                    <MenuItem value={99}>
                                                        Master Admin
                                                    </MenuItem>
                                                    <MenuItem value={30}>
                                                        Account Admin
                                                    </MenuItem>
                                                    <MenuItem value={20}>
                                                        Location Admin
                                                    </MenuItem>
                                                    <MenuItem value={0}>
                                                        Member
                                                    </MenuItem>
                                                </Select>
                                            </div>
                                        </div>

                                        {
                                            !this.isUserRoleSuperAdmin() &&
                                            (
                                                <div className={classNames(classes.formFieldRow, "flex flex-row")}>
                                                    <div className="min-w-1/3">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            Account:
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-1">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            {
                                                                user.assigned_accounts.length == 0
                                                                    ?
                                                                    'Not available'
                                                                    :
                                                                    user.assigned_accounts.join(', ')
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {
                                            (!this.isUserRoleSuperAdmin() && !this.isUserRoleAccountAdmin()) &&
                                            (
                                                <div className={classNames(classes.formFieldRow, "flex flex-row")}>
                                                    <div className="min-w-1/3">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            Location:
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-1">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            {
                                                                user.assigned_locations.length == 0
                                                                    ?
                                                                    'Not available'
                                                                    :
                                                                    user.assigned_locations.join(', ')
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {
                                            !hideUnusedFields && (
                                                <div className={classNames(classes.formFieldRow, "flex flex-row")}>
                                                    <div className="min-w-1/3">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            Sector:
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-1">
                                                        <Typography className={classNames(classes.formFieldText)}>
                                                            Jesus
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className={classNames(classes.formFieldRow, "flex flex-row mt-24")}>
                                            <div className="min-w-1/3">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    Contact Number:
                                                </Typography>
                                            </div>
                                            <div className="flex flex-1">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    {user.contact}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className={classNames(classes.formFieldRow, "flex flex-row")}>
                                            <div className="min-w-1/3">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    Email:
                                                </Typography>
                                            </div>
                                            <div className="flex flex-1">
                                                <Typography className={classNames(classes.formFieldText)}>
                                                    {user.email}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="flex mt-24">
                                            <Typography
                                                className={classes.textSupportLink}
                                                component={Link}
                                                to="/support"
                                            >
                                                Click here to contact tech support regarding your password
                                            </Typography>
                                        </div>

                                        {
                                            !isOwnProfile && (
                                                <div className="flex flex-row mt-128 mb-24">
                                                    <Button
                                                        className={classNames(classes.button, "primary")}
                                                        color="actionPrimary"
                                                        variant="contained"
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.button, "ml-8 primary")}
                                                        color="actionPrimary"
                                                        variant="contained"
                                                    >
                                                        Save & Exit
                                                    </Button>
                                                    <Button
                                                        className={classNames(classes.button, "ml-8 neutral")}
                                                        variant="contained"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )
                                        }

                                    </div>

                                </div>
                            )
                    }
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadAvatar: Actions.uploadAvatar,
        removeAvatar: Actions.removeAvatar,
        getUserProfile: profileActions.getUserProfile
    }, dispatch);
}

function mapStateToProps({ auth, profile }) {
    const user = auth.user;
    return {
        userId: user ? user.uid : undefined,
        user: profile.user.data
    };
}

export default (withStyles(
    styles,
    {
        withTheme: true
    }
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileTab))));