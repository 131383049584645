import * as Yup from 'yup';

export default Yup.object().shape({
  account: Yup.string().required('Account is a required field'),
  name: Yup.string().required('Location Name is a required field'),
  location_id: Yup.string().required('Location ID is a required field'),
  sector: Yup.string().required('Sector is a required field'),
  address: Yup.string().required('Address is a required field'),
  invested_amt: Yup.number()
    .typeError("Invested Amount must be a 'number' type")
    .required('Invested Amount is a required field'),
  tariff: Yup.number()
    .typeError("Tariff must be a 'number' type")
    .required('Tariff is a required field'),
  panel_brand: Yup.string().required('Panel brand is a required field'),
  panel_count: Yup.number()
    .typeError("Panel count must be a 'number' type")
    .required('Panel count is a required field'),
  panel_rating: Yup.number()
    .typeError("Panel rating must be a 'number' type")
    .required('Panel rating is a required field'),
  system_size: Yup.number()
    .typeError("System size must be a 'number' type")
    .required('System size is a required field'),
  panel_area: Yup.number()
    .typeError("Panel area must be a 'number' type")
    .required('Panel area is a required field'),
  panel_efficiency: Yup.number()
    .typeError("Panel efficiency must be a 'number' type")
    .required('Panel efficiency is a required field')

  //   contact_person: Yup.string().required(),
  //   contact_number: Yup.number().required()
  //   region: Yup.array()
  //     .of(Yup.string())
  //     .required()
});
