import React, { Component } from "react";
import Formsy from "formsy-react";
import { MIAutoFillTextFieldFormsy } from "@massiveinfinity";
import {
  withStyles,
  Button,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as Actions from "auth/store/actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";

import { withCookies } from "react-cookie";

const styles = theme => ({
  root: {
    width: "100%"
  },
  wrapper: {
    width: "316px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "white"
  }
});

class FirebaseLoginTab extends Component {
  state = {
    canSubmit: false,
    isLoading: false
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = model => {
    const { cookies } = this.props;
    this.props.submitLoginWithFireBase(model, cookies);
    this.setState({
      isLoading: true
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { login } = this.props;
    if (
      login.error &&
      (login.error.email || login.error.password || login.error.tooManyRequests)
    ) {
      if (!login.error.tooManyRequests) {
        const formErrors = _.pick(login.error, ["email", "password"]);
        this.form.updateInputsWithError({
          ...formErrors
        });
      }

      this.props.login.error = null;
      this.setState({
        isLoading: false
      });
    }

    if (this.props.user.role !== "guest") {
      const pathname =
        this.props.location.state && this.props.location.state.redirectUrl
          ? this.props.location.state.redirectUrl
          : "/";
      this.props.history.push({
        pathname
      });
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const { canSubmit, isLoading } = this.state;

    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className="flex flex-col justify-center w-full"
        >
          <p
            style={{
              fontSize: 15,
              textAlign: "center",
              color: "White"
            }}
          >
            P O W E R M O N
          </p>

          <p
            style={{
              fontSize: 9,
              textAlign: "center",
              marginBottom: 30,
              color: "White"
            }}
          >
            POWER MONITORING
          </p>

          <div
            style={{
              margin: "auto",
              marginBottom: 16,
              padding: 8,
              backgroundColor: "white"
            }}
          >
            <MIAutoFillTextFieldFormsy
              className="italic bg-white"
              style={{
                width: 300
              }}
              type="text"
              name="email"
              label="User ID"
              validations={{
                minLength: 4
              }}
              validationErrors={{
                minLength: "Min character length is 4"
              }}
              InputProps={{
                endAdornment: <InputAdornment position="start" />,
                disableUnderline: true
              }}
              variant="standard"
              required
            />
          </div>

          <div
            style={{
              margin: "auto",
              marginBottom: 16,
              padding: 8,
              backgroundColor: "white"
            }}
          >
            <MIAutoFillTextFieldFormsy
              className="italic bg-white"
              style={{
                width: 300
              }}
              type="password"
              name="password"
              label="Password"
              validations={{
                minLength: 4
              }}
              validationErrors={{
                minLength: "Min character length is 4"
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end" />,
                disableUnderline: true
              }}
              // InputLabelProps={{
              //   shrink: true
              // }}
              //margin="dense"
              variant="standard"
              required
            />
          </div>

          <div className={classes.wrapper}>
            <Button
              disabled={!canSubmit || isLoading}
              type="submit"
              variant="contained"
              color="#0eabe0"
              className="w-full normal-case"
              aria-label="LOG IN"
              value="legacy"
            >
              LOG IN
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>

          <p
            className="mt-20"
            style={{ fontSize: 11, textAlign: "center", color: "White" }}
          >
            Don't have an account? If you are an owner of a pylon,{" "}
            <a href="https://pyloncity.com/#contact" style={{ color: "White" }}>
              CONTACT SUPPORT HERE.
            </a>
          </p>
        </Formsy>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: Actions.submitLoginWithFireBase
    },
    dispatch
  );
}

function mapStateToProps({ auth }, { cookies }) {
  return {
    login: auth.login,
    user: auth.user,
    cookies: cookies
  };
}

export default withCookies(
  withStyles(styles, {
    withTheme: true
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FirebaseLoginTab)))
);
