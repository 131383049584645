import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "auth/store/actions";
import { bindActionCreators } from "redux";
import * as Actions from "store/actions";
import firebaseService from "firebaseService";
import jwtService from "jwtService";
import { withCookies } from "react-cookie";
import jwtDecode from "jwt-decode";

class MIJWTAuth extends Component {
  state = {
    isJWTTokenChecked: null
  };

  constructor(props) {
    super(props);
    this.jwtCheck();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRole !== this.props.userRole) {
      if (this.props.userRole == 30) {
        this.props.setAccAdminNavigation();
      } else if (this.props.userRole == 20) {
        this.props.setLocAdminNavigation();
      } else {
        this.props.setDefaultNavigation();
      }
      if (!this.state.isJWTTokenChecked) {
        this.setState({
          isJWTTokenChecked: true
        });
      }
    }
    if (this.props.cookies.cookies.auth_token) {
      let token = this.props.cookies.cookies.auth_token;
      let IsTokenValid = () =>
        setInterval(() => {
          console.log("prev cookies", prevProps.cookies);
          console.log("cookies", this.props.cookies);
          console.log("IsTokenValid", this.isAuthTokenValid(token));
          if (!this.isAuthTokenValid(token)) {
            console.log("Token invalid");
            clearInterval(IsTokenValid);
            this.props.logout();
          }
        }, 300000);

      IsTokenValid();
    }
  }

  isAuthTokenValid = access_token => {
    console.log("Access token", access_token);
    if (!access_token) {
      return true;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    console.log("decodedExp", decoded);
    console.log("currentTime", currentTime);
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  jwtCheck = () => {
    const { cookies } = this.props;

    jwtService.on("onAutoLogin", () => {
      /**
       * Sign in and retrieve user data from Api
       */
      jwtService
        .signInWithToken()
        .then(user => {
          this.props.setUserData(user);

          // Initialize Firebase Service here.
          // Since LOCAL level persistence is applied during login (even by default),
          // the auth user data should still be stored locally.
          firebaseService.init();

          this.setState({
            isJWTTokenChecked: true
          });
        })
        .catch(error => {
          this.props.showMessage({ message: error });
          this.props.setGuestUserData();
          this.setState({
            isJWTTokenChecked: true
          });
        });
    });

    jwtService.on("onAutoLogout", message => {
      if (message) {
        this.props.showMessage({ message });
      }

      this.props.setGuestUserData();
      this.setState({
        isJWTTokenChecked: true
      });
    });

    jwtService.on("onUnauthenticated", () => {
      this.props.setGuestUserData();
    });

    jwtService.init(cookies);
  };

  render() {
    const { children } = this.props;
    const { isJWTTokenChecked } = this.state;

    return <React.Fragment>{isJWTTokenChecked && children}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setGuestUserData: userActions.setGuestUserData,
      setUserData: userActions.setUserData,
      setUserDataAuth0: userActions.setUserDataAuth0,
      setUserDataFirebase: userActions.setUserDataFirebase,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
      setAccAdminNavigation: Actions.setAccAdminNavigation,
      setLocAdminNavigation: Actions.setLocAdminNavigation,
      setDefaultNavigation: Actions.setDefaultNavigation
    },
    dispatch
  );
}

function mapStateToProps({ auth }, { cookies }) {
  return {
    userRole: auth.user.role,
    cookies: cookies,
    isAuthenticated: auth.user.isAuthenticated
  };
}

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(MIJWTAuth)
);
