import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles/index";
import { Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import FirebaseLoginTab from "./tabs/FirebaseLoginTab";
import logo from './assets/pwm_logo.png'

const styles = (theme) => ({
  root: {
    background:
      "url('/assets/pylon/ppt_bg.png') repeat-y",
    backgroundSize: "cover",
    backgroundColor: '#02BFFF'
  },
  intro: {
    color: "#ffffff"
  },
  card: {
    width: "100%",
    maxWidth: 400
  }
});

class Login extends Component {
  render() {
    const { classes } = this.props;

    return (
      <body style={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: "url('/assets/pylon/ppt_bg.png')",
        height: '1500px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#02BFFF',
        overflow: 'hidden'
      }}>
        <div className="m-auto">
          <img className="items-center" src={logo} width="410" height="180" mode="fit" ></img>
          <FirebaseLoginTab className="items-center" />
        </div>
      </body >
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Login));
