export const HIDE_MESSAGE = "[MESSAGE] CLOSE";
export const SHOW_MESSAGE = "[MESSAGE] SHOW";

export function hideMessage() {
  return {
    type: HIDE_MESSAGE
  };
}

export function showMessage(options) {
  return {
    type: SHOW_MESSAGE,
    options
  };
}

export function showSuccessMessage(message) {
  message = message === "" ? "Success" : message;
  return {
    type: SHOW_MESSAGE,
    options: {
      message: message,
      variant: "success",
      autoHideDuration: 3000
    }
  };
}

export function showErrorMessage(message) {
  message = message === "" ? "Error" : message;
  return {
    type: SHOW_MESSAGE,
    options: {
      message: message,
      variant: "error",
      autoHideDuration: 3000
    }
  };
}
