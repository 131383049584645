import firebaseService from "firebaseService";
import jwtService from "jwtService";

export const UPLOAD_AVATAR = "[PYLON_AVATAR] UPLOAD";
export const REMOVE_AVATAR = "[PYLON_AVATAR] REMOVE";
export const ERROR_AVATAR = "[PYLON_AVATAR] ERROR";
export const CLEAR_STATE_AVATAR = "[PYLON_AVATAR] CLEAR_STATE";

export function uploadAvatar(userId, file) {
  return dispatch => {
    firebaseService
      .uploadImage(userId, file)
      .then(result => {
        // Result is the image URL.
        return jwtService.updateUserImage(userId, result);
      })
      .then(result => {
        // Result is the image URL.
        console.log("## result", result);
        return dispatch({
          type: UPLOAD_AVATAR,
          payload: result
        });
      })
      .catch(error => {
        console.log(error);

        if (typeof error == "string") {
          return dispatch({
            type: ERROR_AVATAR,
            payload: error
          });
        }

        return dispatch({
          type: ERROR_AVATAR,
          payload: error.message
        });
      });
  };
}

export function removeAvatar(userId, imageURL) {
  return dispatch => {
    firebaseService
      .removeImage(imageURL)
      .then(() => {
        return jwtService.updateUserImage(userId, "");
      })
      .then(() => {
        return dispatch({
          type: REMOVE_AVATAR
        });
      })
      .catch(error => {
        console.log(error);

        if (typeof error == "string") {
          return dispatch({
            type: ERROR_AVATAR,
            payload: error
          });
        }

        return dispatch({
          type: ERROR_AVATAR,
          payload: error.message
        });
      });
  };
}

export function clearAvatarState() {
  return {
    type: CLEAR_STATE_AVATAR
  };
}
