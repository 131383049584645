import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Avatar,
  IconButton,
  Icon,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Button
} from "@material-ui/core";
import classNames from "classnames";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const styles = theme => ({
  avatar: {
    width: 220,
    height: 220,
    borderRadius: "0px"
  },
  buttonContainers: {
    position: "absolute",
    bottom: "12px"
  },
  button: {
    padding: "4px 12px",
    color: "rgba(255, 255, 255, 0.3)",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "0px",
    "&:hover": {
      color: "rgba(255, 255, 255, 0.65)",
      backgroundColor: "rgba(0, 0, 0, 0.4)"
    }
  },
  centerProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-20px"
  }
});

class UploadAccountAvatar extends Component {
  state = {
    isLoading: false,
    openDialog: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { avatar } = this.props;

    if (!avatar.success && avatar.message !== "") {
      this.props.showErrorMessage(avatar.message);
      this.props.clearState();
      this.setState({
        isLoading: false
      });
    } else if (avatar.success == true) {
      this.props.showSuccessMessage(avatar.message);
      this.props.clearState();
      this.setState({
        isLoading: false
      });
    }
  }

  onImageSelectedForUpload = e => {
    this.setState({
      isLoading: true
    });

    const file = e.currentTarget.files[0];
    this.props.uploadAvatar(this.props.accountID, file);
  };

  onClickRemoveButton = () => {
    if (this.state.isLoading) {
      return;
    }

    const { src } = this.props;
    if (src === undefined || src === "" || typeof src == "object") {
      console.log(
        "Image does not exist, does not make sense to delete an inexistent image. Not going to do anything else here."
      );
      return;
    }

    this.setState({
      openDialog: true
    });
  };

  onClickDialogClose = () => {
    this.setState({
      openDialog: false
    });
  };

  onClickDialogOK = () => {
    this.setState({
      openDialog: false,
      isLoading: true
    });

    const { src } = this.props;
    this.props.removeAvatar(this.props.accountID, src);
  };

  render() {
    const { classes, src, alt } = this.props;
    const { isLoading, openDialog } = this.state;

    return (
      <div className="relative">
        {src === undefined || src === "" || typeof src == "object" ? (
          <Avatar className={classes.avatar} alt={alt}>
            {/* {name[0]} */}
            No Image Available
          </Avatar>
        ) : (
          <Avatar className={classes.avatar} alt={alt} src={src} />
        )}

        {isLoading && (
          <CircularProgress
            className={classes.centerProgress}
            color="primary"
          />
        )}

        <div
          className={classNames(
            classes.buttonContainers,
            "w-full flex flex-row items-center"
          )}
        >
          <div className="flex flex-1" />

          <input
            id="avatar-input"
            type="file"
            accept="image/*"
            ref={ref => (this.upload = ref)}
            style={{ display: "none" }}
            onChange={this.onImageSelectedForUpload}
          />
          <IconButton
            className={classes.button}
            aria-label="Upload"
            onClick={e => {
              if (!isLoading) {
                this.upload.click();
              }
            }}
          >
            <Icon className="text-32">cloud_upload</Icon>
          </IconButton>

          <IconButton
            className={classes.button}
            aria-label="Delete"
            onClick={this.onClickRemoveButton}
          >
            <Icon className="text-32">delete</Icon>
          </IconButton>

          <div className="flex flex-1" />
        </div>

        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to remove picture?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.onClickDialogClose} color="primary">
              No
            </Button>
            <Button onClick={this.onClickDialogOK} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
      uploadAvatar: Actions.uploadAccountAvatar,
      removeAvatar: Actions.removeAccountAvatar,
      clearState: Actions.clearAvatarState
    },
    dispatch
  );
}

function mapStateToProps({ accounts }) {
  const { avatar } = accounts;
  const { success, message } = avatar;

  return {
    avatar: {
      success,
      message
    }
  };
}

export default withStyles(styles, {
  withTheme: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UploadAccountAvatar)
);
