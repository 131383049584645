import React from 'react';
import LocationTab from './tabs/LocationTab';
import AlertsAlarmsTab from './tabs/AlertsAlarmsTab';
import AssetTab from './tabs/AssetTab';
import ZoneTab from './tabs/ZoneTab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TriangleAlertIcon from '../../assets/triangleAlert';
import EnerygyIcon from '../../assets/enerygyIcon';
import AssetIcon from '../../assets/assetIcon';
import ZoneIcon from '../../assets/zoneIcon.png';

function TabContainer({ children, dir }) {
  return <div style={{ padding: 24, marginBottom: 50 }}>{children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  flexContainer: {
    justifyContent: 'space-between',
  },
  defaultTabStyle: {
    width: 400,
    flexWrap: 'wrap',
  },
});

class LocationPageContent extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    if (value === 2) {
      this.props.onChangeToZoneTab(true);
    } else {
      this.props.onChangeToZoneTab(false);
    }
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme, accountId, locationId, inverters } = this.props;
    return (
      <div
        style={{
          // height: window.innerHeight,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          paddingTop: 20,
        }}
      >
        <AppBar
          position="static"
          color="default"
          style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}
        >
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="false"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              flexContainer: classes.flexContainer,
            }}
          >
            <Tab
              className={classes.defaultTabStyle}
              style={
                this.state.value === 0
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justify-center items-center">
                  <EnerygyIcon />
                  <Typography>ENERGY & POWER</Typography>
                </div>
              }
            />
            <Tab
              className={classes.defaultTabStyle}
              style={
                this.state.value === 1
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justify-center items-center">
                  <TriangleAlertIcon />
                  <Typography>ALERT & ALARMS</Typography>
                </div>
              }
            />
            <Tab
              className={classes.defaultTabStyle}
              style={
                this.state.value === 2
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justify-center items-center">
                  <img src={ZoneIcon} />
                  <Typography className="ml-8">ZONES</Typography>
                </div>
              }
            />
            <Tab
              className={classes.defaultTabStyle}
              style={
                this.state.value === 3
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justify-center items-center">
                  <AssetIcon />
                  <Typography>ASSETS & MAINTENANCE</Typography>
                </div>
              }
            />
          </Tabs>
        </AppBar>
        {this.state.value === 0 && (
          <TabContainer>
            <LocationTab accountId={accountId} locationId={locationId} />
          </TabContainer>
        )}

        {this.state.value === 1 && (
          <TabContainer>
            <AlertsAlarmsTab />
          </TabContainer>
        )}

        {this.state.value === 2 && (
          <TabContainer>
            <ZoneTab />
          </TabContainer>
        )}

        {this.state.value === 3 && (
          <TabContainer>
            <AssetTab
              accountId={accountId}
              locationId={locationId}
              inverters={inverters}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

LocationPageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LocationPageContent);
