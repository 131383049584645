import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import classNames from "classnames";
import {
  Icon,
  Typography,
  Button,
  Avatar,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import * as quickPanelActions from "main/quickPanel/store/actions";
import { bindActionCreators } from "redux";
import * as authActions from "auth/store/actions";
import * as Actions from "store/actions";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";
import logo from "./assets/pwm_logo.png";
import { withRouter } from "react-router-dom";
import { PylonClock, PylonToolbarData } from "@pylon";
import _ from "@lodash";
import PylonToolbarProfile from "../@pylon/components/PylonToolbarProfile";
import { is } from "immutable";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  seperator: {
    width: 1,
    height: 24,
    margin: "auto 0",
    backgroundColor: theme.palette.divider
  },
  logoIcon: {
    height: 24,
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  logoText: {
    color: "#FFFFFF"
  }
});

class MainToolbar extends Component {
  state = {
    userMenu: null,
    width: window.innerWidth
  };

  componentDidMount() {
    this.checkUserPropExist();
    window.addEventListener("resize", this.handleWindowSizeChange);
    // this.props.user.role == 0 && alert(123);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props.user;

    if (
      prevProps.user.role != this.props.user.role &&
      this.props.user.role == 0
    ) {
      this.userDrawerButtonClick();
    }

    if (data == null || _.isEqual(prevProps.data, data)) {
      return;
    }
    this.checkUserPropExist();
  }

  checkUserPropExist = () => {
    const { data, role } = this.props.user;
    const accounts = data.assigned_accounts;
    if (!accounts) {
      return;
    } else if (accounts.length < 0 || accounts[0] == "") {
      return;
    } else if (role == 99 || role == 20) {
      return;
    } else {
      return this.props.getTotalEnergyGenerated(accounts[0]);
    }
  };

  userDrawerButtonClick = () => {
    this.props.setDefaultSettings(
      _.set(
        {},
        "layout.config.navbar.display",
        !this.props.settings.layout.config.navbar.display
      )
    );
  };

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget });
  };

  userMenuClose = () => {
    this.setState({ userMenu: null });
  };

  onClickAppLogo = () => {
    this.props.history.push({
      pathname: "/"
    });
  };

  onClickMenuViewProfile = () => {
    // Close the pop up.
    this.userMenuClose();

    this.props.history.push({
      pathname: "/profile",
      state: {
        isOwnProfile: true
      }
    });
  };

  onClickMenuLogout = () => {
    this.props.logout();
    this.userMenuClose();
  };

  isUserRoleSuperAdmin = () => {
    const { user } = this.props;
    console.log(user);

    if (!user) {
      return false;
    }

    if (user.role == undefined) {
      return false;
    }

    if (user.role != 99) {
      return false;
    }

    return true;
  };

  isUserRoleMember = () => {
    const { user } = this.props;

    if (!user) {
      return false;
    }

    if (user.role == undefined) {
      return false;
    }

    if (user.role != 0) {
      return false;
    }

    return true;
  };

  isUserRoleNewAccount = () => {
    const { user } = this.props;
    console.log(user);

    if (!user) {
      return false;
    }

    if (user.role == undefined) {
      return false;
    }

    if (user.role != 0) {
      return false;
    }

    return true;
  };

  render() {
    const { classes, user } = this.props;
    const { userMenu, width } = this.state;
    const isMobile = this.isMobileDevice();
    // Flag here to just hide view that are currently not needed.
    // Not sure if it'll be needed in the future.
    const hideUnusedViews = true;

    if (isMobile) {
      return (
        <div
          className={classNames(classes.root, "flex flex-row justify-between")}
        >
          <div className="flex">
            <FuseAnimate delay={300}>
              <Button className="h-64 min-w-0" onClick={this.onClickAppLogo}>
                <img
                  className={classNames(classes.logoIcon, "logo-icon")}
                  src={logo}
                  alt="logo"
                />
              </Button>
            </FuseAnimate>
          </div>

          {this.isUserRoleSuperAdmin() ? (
            <IconButton onClick={this.userDrawerButtonClick}>
              <Icon className={classNames(classes.logoText)}>more_vert</Icon>
            </IconButton>
          ) : (
            <div />
          )}

          {!this.isUserRoleNewAccount() && <PylonToolbarData />}
          <div className="flex justify-end item-right">
            <FuseAnimate delay={300}>
              <Button className="h-64" onClick={this.userMenuClick}>
                <PylonToolbarProfile />
              </Button>
            </FuseAnimate>

            <Popover
              open={Boolean(userMenu)}
              anchorEl={userMenu}
              onClose={this.userMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              classes={{
                paper: "py-8"
              }}
            >
              {user.role === "guest" ? (
                <React.Fragment>
                  <MenuItem component={Link} to="/login">
                    <ListItemIcon>
                      <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Login" />
                  </MenuItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MenuItem onClick={this.onClickMenuViewProfile}>
                    <ListItemIcon>
                      <Icon>account_circle</Icon>
                    </ListItemIcon>
                    <ListItemText
                      className="pl-0"
                      primary="View Profile"
                      classes={{ primary: "text-14" }}
                    />
                  </MenuItem>
                  <MenuItem onClick={this.onClickMenuLogout}>
                    <ListItemIcon>
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText
                      className="pl-0 text-14"
                      primary="Logout"
                      classes={{ primary: "text-14" }}
                    />
                  </MenuItem>
                </React.Fragment>
              )}
            </Popover>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames(classes.root, "flex flex-row")}>
          <div className="flex items-center">
            <FuseAnimate delay={300}>
              <Button className="h-64" onClick={this.onClickAppLogo}>
                <img
                  className={classNames(classes.logoIcon, "logo-icon")}
                  src={logo}
                  alt="logo"
                />
                <Typography
                  className={classNames(
                    classes.logoText,
                    "text-16 ml-8 uppercase"
                  )}
                >
                  PYLON CITY
                </Typography>
                <Typography
                  className={classNames(classes.logoText, "text-12 ml-4")}
                >
                  :
                </Typography>
                <Typography
                  className={classNames(
                    classes.logoText,
                    "text-12 ml-4 uppercase"
                  )}
                >
                  POWERMON
                </Typography>
              </Button>
            </FuseAnimate>

            {this.isUserRoleMember() ? (
              <div />
            ) : (
              <IconButton
                className="mr-8 ml-24"
                onClick={this.userDrawerButtonClick}
              >
                <Icon className={classNames(classes.logoText)}>more_vert</Icon>
              </IconButton>
            )}
          </div>

          <div className="flex flex-1" />

          {!this.isUserRoleNewAccount() && <PylonToolbarData />}

          <div className="flex flex-1" />

          <div className="flex items-center">
            <PylonClock />

            {!hideUnusedViews && (
              <Icon className="text-22 mr-16" variant="action">
                notifications_none
              </Icon>
            )}

            <div className={classes.seperator} />

            <FuseAnimate delay={300}>
              <Button className="h-64" onClick={this.userMenuClick}>
                <PylonToolbarProfile />
              </Button>
            </FuseAnimate>

            <Popover
              open={Boolean(userMenu)}
              anchorEl={userMenu}
              onClose={this.userMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              classes={{
                paper: "py-8"
              }}
            >
              {user.role === "guest" ? (
                <React.Fragment>
                  <MenuItem component={Link} to="/login">
                    <ListItemIcon>
                      <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Login" />
                  </MenuItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MenuItem onClick={this.onClickMenuViewProfile}>
                    <ListItemIcon>
                      <Icon>account_circle</Icon>
                    </ListItemIcon>
                    <ListItemText
                      className="pl-0"
                      primary="View Profile"
                      classes={{ primary: "text-14" }}
                    />
                  </MenuItem>
                  <MenuItem onClick={this.onClickMenuLogout}>
                    <ListItemIcon>
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText
                      className="pl-0 text-14"
                      primary="Logout"
                      classes={{ primary: "text-14" }}
                    />
                  </MenuItem>
                </React.Fragment>
              )}
            </Popover>
          </div>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: quickPanelActions.toggleQuickPanel,
      logout: authActions.logoutUser,
      getTotalEnergyGenerated: Actions.getTotalEnergyGenerated,
      setDefaultSettings: Actions.setDefaultSettings
    },
    dispatch
  );
}

function mapStateToProps({ fuse, auth, pylon }) {
  return {
    settings: fuse.settings.current,
    user: auth.user
  };
}

export default withStyles(styles, {
  withTheme: true
})(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MainToolbar)
  )
);
