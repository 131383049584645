import * as Actions from "../../actions/fuse/index";
import { fuseNavigationConfig } from "fuse-configs/fuseNavigationConfig";
import { fuseAccountAdminNavigationConfig } from "fuse-configs/fuseAccountAdminNavigationConfig";
import { fuseLocationAdminNavigationConfig } from "fuse-configs/fuseLocationAdminNavigationConfig";

const initialState = fuseNavigationConfig;

const navigation = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_NAVIGATION: {
      return [...initialState];
    }
    case Actions.SET_ACC_ADMIN_NAVIGATION: {
      return fuseAccountAdminNavigationConfig;
    }
    case Actions.SET_DEFAULT_NAVIGATION: {
      return fuseNavigationConfig;
    }
    case Actions.SET_LOC_ADMIN_NAVIGATION: {
      return fuseLocationAdminNavigationConfig;
    }
    default: {
      return state;
    }
  }
};

export default navigation;
