// Pylon Cloud FIrestore (production)
var prodConfig = {
  apiKey: "AIzaSyChWuzvsD3DzH6uFGlR6M4lxsGLx10bF_4",
  authDomain: "pylon-cloud.firebaseapp.com",
  databaseURL: "https://pylon-cloud.firebaseio.com",
  projectId: "pylon-cloud",
  storageBucket: "pylon-cloud.appspot.com",
  messagingSenderId: "889180878397"
};

// Pylon Cloud Firestore (staging)
const devConfig = {
  apiKey: "AIzaSyBQsILQPIHKPNs6LQAI4lCOESoMGz06_SM",
  authDomain: "pylon-powermon.firebaseapp.com",
  databaseURL: "https://pylon-powermon.firebaseio.com",
  projectId: "pylon-powermon",
  storageBucket: "pylon-powermon.appspot.com",
  messagingSenderId: "122556926176"
};

 //const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const config = process.env.REACT_APP_KEY === "dev" ? devConfig : prodConfig;

export default config;
