/**
 * Authorization Roles
 */
const authRoles = {
    admin: [99],
    account: [99, 30],
    location: [99, 30, 20],
    new: [99, 30, 20, 0],

    staff    : [0, 'staff'],
    user     : [0, 'staff', 'user'],
    onlyGuest: ['guest']
};

export default authRoles;
