import { authRoles } from "auth";
import Dashboard from "./Dashboard";

export const DashboardConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false
        }
      }
    }
  },
  auth: authRoles.location,
  routes: [
    {
      path: "/dashboard",
      component: Dashboard
    }
  ]
};
