import firebase from 'firebase/app';

export const utilGetInitialPageAlarmAndAlerts = (accountId, locationId) => {
  let db = firebase.firestore();

  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('overview')
    .doc('alarm_history')
    .collection('log')
    .orderBy('group', 'desc')
    .orderBy('timestamp', 'desc')
    .limit(15);
};

export const utilGetNextPageAlarmAndAlerts = (
  accountId,
  locationId,
  paramLastField
) => {
  let db = firebase.firestore();

  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('overview')
    .doc('alarm_history')
    .collection('log')
    .orderBy('group', 'desc')
    .orderBy('timestamp', 'desc')
    .startAfter(paramLastField)
    .limit(15);
};

export const utilGetPrevPageAlarmsAndAlerts = (
  accountId,
  locationId,
  paramFirstField
) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('overview')
    .doc('alarm_history')
    .collection('log')
    .orderBy('group', 'desc')
    .orderBy('timestamp', 'desc')
    .endBefore(paramFirstField)
    .limitToLast(15);
};

export const utilGetAlarmCollectionCount = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('overview')
    .doc('alarm_history')
    .collection('log');
};

export const getLocationDetails = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId);
};

export const getLatestEnergyConsumption = (accountId, locationId, _type) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('overview')
    .doc(_type)
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

////////////////////////////
//     Meter Data        //
////////////////////////////

export const getInverterMeter = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('inverter')
    .orderBy('status_code', 'desc');
};

export const getExpMeter = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('export_meter');
};

export const getIRMeter = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('ir_meter');
};

export const getGenMeter = (accountId, locationId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('gen_meter');
};

export const getGenMeterIdDetail = (accountId, locationId, genMeterId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('gen_meter')
    .doc(genMeterId)
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getExportMeterDetail = (accountId, locationId, exportMeterId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('export_meter')
    .doc(exportMeterId)
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getInverterIdDetail = (accountId, locationId, inverterId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('inverter')
    .doc(inverterId)
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getIRMeterDetail = (accountId, locationId, irMeterId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('ir_meter')
    .doc(irMeterId)
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};
export const getInverterIdMaintenance = (
  accountId,
  locationId,
  inverterId,
  docId
) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('inverter')
    .doc(inverterId)
    .collection('log')
    .doc(docId)
    .collection('maintenance');
};

export const getLatestInverterAlarmLog = (
  accountId,
  locationId,
  inverterId
) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('inverter')
    .doc(inverterId)
    .collection('alarm_log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getAlarmLog = (accountId, locationId, inverterId, docId) => {
  let db = firebase.firestore();
  return db
    .collection('account')
    .doc(accountId)
    .collection('location')
    .doc(locationId)
    .collection('inverter')
    .doc(inverterId)
    .collection('alarm_log')
    .doc(docId)
    .collection('log')
    .orderBy('status_code', 'desc');
};

////////////////////////////
//    Zone Tab     //
////////////////////////////

export const getZones = (locationId) => {
  let db = firebase.firestore();
  return db
    .collection('location')
    .doc(locationId)
    .collection('zone');
};

export const getZoneReadings = (locationId, zoneId) => {
  let db = firebase.firestore();
  return db
    .collection('location')
    .doc(locationId)
    .collection('zone')
    .doc(zoneId)
    .collection('overview')
    .doc('monthly')
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getZoneDevices = (locationId, zoneId) => {
  let db = firebase.firestore();
  return db
    .collection('location')
    .doc(locationId)
    .collection('zone')
    .doc(zoneId)
    .collection('metered_relay_switch');
};

export const getZoneDeviceReadings = (locationId, zoneId, deviceId) => {
  let db = firebase.firestore();
  return db
    .collection('location')
    .doc(locationId)
    .collection('zone')
    .doc(zoneId)
    .collection('metered_relay_switch')
    .doc(deviceId)
    .collection('overview')
    .doc('monthly')
    .collection('log')
    .orderBy('timestamp', 'desc')
    .limit(1);
};

export const getZoneChartDataByFilter = (locationId, zoneId, filter) => {
  let db = firebase.firestore();
  return db
    .collection('location')
    .doc(locationId)
    .collection('zone')
    .doc(zoneId)
    .collection('overview')
    .doc(filter)
    .collection('log');
};
