import React, { Component } from "react";
import { matchRoutes } from "react-router-config";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "@lodash";

let redirect = false;

class MIJWTAuthorization extends Component {
  constructor(props) {
    super(props);
    this.checkAuth();
  }

  componentDidUpdate(prevProps) {
    /**
     * If route is changed
     * Update auths
     */
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.checkAuth();
    }

    if (!_.isEqual(this.props.user.role, prevProps.user.role)) {
      this.checkAuth();
    }
  }

  checkAuth() {
    const matched = matchRoutes(
      this.props.routes,
      this.props.location.pathname
    )[0];
    if (matched && matched.route.auth && matched.route.auth.length > 0) {
      if (!matched.route.auth.includes(this.props.user.role)) {
        redirect = true;
        this.navigateToAppIfVerifiedElseLogin();
      }
    } else if (this.props.location.pathname === "/") {
      this.navigateToAppIfVerifiedElseLogin();
    } else if (this.props.user.role === "guest") {
      this.navigateToLogin();
    }
  }

  navigateToAppIfVerifiedElseLogin() {
    if (this.props.user.role === "guest") {
      this.navigateToLogin();
    } else {
      this.navigateToDashboard();
    }
  }

  navigateToLogin = () => {
    this.props.history.push({
      pathname: "/login",
      state: { redirectUrl: this.props.location.pathname }
    });
  };

  navigateToDashboard = () => {
    console.log("## navigate to dashboard");
    console.log(this.props);
    this.props.history.push({
      pathname: this.getRedirectionLocationByUserRole()
    });
  };

  getRedirectionLocationByUserRole = () => {
    const { role, data } = this.props.user;

    // if (role == 0) {
    //   const locations = data.assigned_locations;
    //   const accounts = data.assigned_accounts;
    //   return "/support";
    // }
    // else if (role == 20 || role==0) {

    if (role == 20 || role == 0) {
      const locations = data.assigned_locations;
      const accounts = data.assigned_accounts;
      if (accounts.length < 0 || !accounts[0]) {
        return "/support";
      } else if (locations.length < 0 || !locations[0]) {
        return "/support";
      } else {
        const location = locations[0];
        const account = accounts[0];
        return "/location/" + account + "/" + location;
      }
    } else if (role == 30) {
      const accounts = data.assigned_accounts;
      if (accounts.length < 0 || !accounts[0]) {
        return "/support";
      }
    } else if (role == 99) {
      const accounts = data.assigned_accounts;
      if (accounts.length < 0 || !accounts[0]) {
        return "/dashboard";
      }
    }

    return "/dashboard";
  };

  shouldComponentUpdate(nextProps) {
    if (redirect) {
      redirect = false;
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ fuse, auth }) {
  return {
    user: auth.user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MIJWTAuthorization)
);
