import React, { PropTypes, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import { FuseAnimate, FusePageSimple } from '@fuse';
import { Typography } from '@material-ui/core';
import { PylonForm } from '@pylon';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import withReducer from 'store/withReducer';
import reducer from './store/reducers';
import * as locationActions from './store/actions';
import _ from 'lodash';
import LocationPageContent from './LocationPageContent';
import {
  utilGetAlarmCollectionCount,
  getLocationDetails,
  getLatestEnergyConsumption,
} from '../location/tabs/utils';
//assets
import InverterIcon from '../../assets/inverterIcon';
import StackedInverterIcon from '../../assets/stackedInverterIcon';
import './location.css';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  layoutHeader: {
    position: 'relative',
    backgroundColor: '#014060',
    height: 320,
    minHeight: 320,
    color: '#000',
    [theme.breakpoints.down('md')]: {
      height: 320,
      minHeight: 320,
    },
  },
  imageHeader: {
    position: 'absolute',
    backgroundColor: '#014060',
    backgroundSize: 'cover',
    filter: `brightness(20%)`,
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  noImageHeader: {
    backgroundColor: '#014060',
  },
  zIndex: {
    zIndex: 10,
  },
});

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: this.props.match.params.accountId,
      locationId: this.props.match.params.locationId,
      center: {
        lat: 1.35,
        lng: 103.82,
      },
      zoom: 12,
      inverters: [],
      width: window.innerWidth,
      alarmCount: 0,
      isEnergyGeneration: true,
      isOnZoneTab: false,
      totalEnergyConsumption: 0,
      monthlyEnergyConsumption: 0,
    };
  }

  componentDidMount() {
    this.props.getLocation(this.state.accountId, this.state.locationId);
    this.props.getOverview(this.state.accountId, this.state.locationId);
    this.props.getMetersId(this.state.accountId, this.state.locationId);
    this.getAlarmCount(this.state.accountId, this.state.locationId);
    this.checkIsEnergyGeneration(this.state.accountId, this.state.locationId);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (location == null || _.isEqual(prevProps.location, location)) {
      return;
    }
    this.props.getLocation(this.state.accountId, this.state.locationId);
    this.props.getOverview(this.state.accountId, this.state.locationId);
    this.props.getMetersId(this.state.accountId, this.state.locationId);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  getAlarmCount = (accountId, locationId) => {
    utilGetAlarmCollectionCount(accountId, locationId).onSnapshot(
      (collectionSnapshot) => {
        let collectionCount = collectionSnapshot.docs.length;
        this.setState({
          alarmCount: collectionCount,
        });
      }
    );
  };

  checkIsEnergyGeneration = (_accountId, _locationId) => {
    getLocationDetails(_accountId, _locationId).onSnapshot(
      (locationDetails) => {
        if (locationDetails.data().hasOwnProperty('isGen')) {
          this.setState({ isEnergyGeneration: locationDetails.data().isGen });

          if (!locationDetails.data().isGen) {
            getLatestEnergyConsumption(
              _accountId,
              _locationId,
              'aggregated'
            ).onSnapshot((valueSnapshot) => {
              this.setState({
                totalEnergyConsumption: valueSnapshot.docs[0].data()
                  .total_energy_consumed,
              });
            });
            getLatestEnergyConsumption(
              _accountId,
              _locationId,
              'monthly'
            ).onSnapshot((valueSnapshot) => {
              this.setState({
                monthlyEnergyConsumption: valueSnapshot.docs[0].data()
                  .energy_consumed,
              });
            });
          }
        }
      }
    );
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  showDashboardLink = () => {
    const role = this.props.user.role;

    if (role == 99 || role == 30) {
      return true;
    } else {
      return false;
    }
  };

  isUserRoleLocationAdmin = () => {
    const { user } = this.props;

    if (!user) {
      return false;
    }

    if (user.role == undefined) {
      return false;
    }

    if (user.role != 20) {
      return false;
    }

    return true;
  };

  countOnlineInverter = (inverters) => {
    let count = 0;
    inverters.map((inverter) => {
      if (inverter.status !== 'offline') {
        count = count + 1;
      }
    });
    return count;
  };

  displayInverterIcon = (status, index) => {
    let colorCode = '';
    switch (status) {
      case 'online':
        colorCode = '#6abd45';
        break;
      case 'offline':
        colorCode = '#666766';
        break;
      case 'warning':
        colorCode = '#f7ec13';
        break;
      case 'major':
        colorCode = '#ec2027';
        break;
      case 'minor':
        colorCode = '#f79420';
        break;
      default:
        colorCode = '#6abd45';
    }

    return (
      <>
        {index <= 4 ? <InverterIcon colorCode={colorCode} /> : null}

        {index === 5 ? <StackedInverterIcon colorCode={colorCode} /> : null}
      </>
    );
  };

  sliceInvertersCount = (invertersArray) => invertersArray.slice(0, 6);

  render() {
    const isShowEnergyConsumption =
      this.state.isOnZoneTab && !this.state.isEnergyGeneration;

    const { alarmCount } = this.state;
    const { classes, location } = this.props;
    const { name, status, img_uri } = location.location.data;
    const {
      total_energy_generated,
      total_power_generated,
    } = location.overview.data;
    const { width } = this.state;
    const isMobile = width <= 500;
    let meters = this.props.location.metersId.data.meters;
    let inverters = _.find(meters, ['type', 'inverter']);
    let uniqueInverter =
      inverters &&
      _.uniqBy(inverters.inverters, function(e) {
        return e.inverter_id;
      });

    let online_inverters = inverters
      ? this.countOnlineInverter(uniqueInverter)
      : 0;

    let limitedInverterArray = inverters
      ? this.sliceInvertersCount(uniqueInverter)
      : [];

    let roundedUpPowerGenerated = (total_power_generated * 1).toFixed(2);
    let roundedUpEnergyGenerated = (total_energy_generated * 1).toFixed(2);
    let roundedUpEnergyConsumed = (
      this.state.totalEnergyConsumption * 1
    ).toFixed(2);
    let roundedUpMonthlyEnergyConsumed = (
      this.state.monthlyEnergyConsumption * 1
    ).toFixed(2);

    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
          header: classes.layoutHeader,
        }}
        innerScroll={false}
        className={'min-h-72 h-72 sm:h-136 sm:min-h-136'}
        header={
          <div
            className={classNames(
              classes.layoutHeader,
              'flex flex-1 flex-col md:flex-col'
            )}
          >
            <div
              className={
                img_uri != '' ? classes.imageHeader : classes.noImageHeader
              }
              style={
                img_uri != '' ? { backgroundImage: `url(${img_uri})` } : null
              }
            ></div>
            <PylonForm
              content={
                <div className={classNames(classes.zIndex, 'w-full')}>
                  <div
                    className={
                      isMobile
                        ? 'flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center md:mx-92 h-full'
                        : 'flex flex-1 flex-row items-center justify-center md:flex-row md:items-center md:justify-center md:mx-92 h-full'
                    }
                  >
                    <div className="flex flex-col md:ml-8 h-full">
                      <div className="flex md:mb-4">
                        <Typography
                          to="/dashboard"
                          component={Link}
                          className={classNames(
                            classes.breadcrumbText,
                            isMobile ? 'text-10' : 'text-14'
                          )}
                        >
                          Dashboard
                        </Typography>
                        <Typography
                          className={
                            isMobile
                              ? 'md:ml-4 md:mr-4 text-10'
                              : 'md:ml-4 md:mr-4'
                          }
                          variant="p"
                          color="secondary"
                        >
                          &nbsp;>&nbsp;
                        </Typography>
                        <Typography
                          to="/dashboard"
                          component={Link}
                          className={classNames(
                            classes.breadcrumbText,
                            isMobile ? 'text-10' : 'text-14'
                          )}
                        >
                          {name}
                        </Typography>
                      </div>

                      <div className="flex flex-row">
                        <Typography
                          color="secondary"
                          className={
                            isMobile ? 'text-10 uppercase' : 'text-18 uppercase'
                          }
                        >
                          {name}
                        </Typography>
                      </div>
                    </div>

                    {isShowEnergyConsumption ? (
                      <>
                        {/* Real time energy consumption container */}
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center">
                          <div className="flex flex-row md:justify-center">
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white text-28 font-600 ml-4 uppercase mt-4'
                                  : 'text-white text-64 font-600 ml-4 uppercase'
                              }
                            >
                              {roundedUpEnergyConsumed.toString().length < 10
                                ? roundedUpEnergyConsumed
                                : (
                                    (roundedUpEnergyConsumed * 1) /
                                    1000
                                  ).toFixed(2)}
                            </Typography>
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white font-300 ml-4 mt-16'
                                  : 'text-white font-300 ml-4 mt-60'
                              }
                            >
                              {roundedUpEnergyConsumed.toString().length < 10
                                ? 'kWh'
                                : 'MWh'}
                            </Typography>
                          </div>
                          <Typography
                            className={'text-white ml-4 uppercase font-300'}
                          >
                            Real time energy consumption
                          </Typography>
                        </div>

                        {/* Real time monthly energy consumption */}
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center">
                          <div className="flex flex-row md:justify-center">
                            <Typography
                              className={
                                isMobile
                                  ? 'text-28 text-white font-100 uppercase mt-4'
                                  : 'text-64 text-white font-100 uppercase'
                              }
                            >
                              {roundedUpMonthlyEnergyConsumed.toString()
                                .length < 10
                                ? roundedUpMonthlyEnergyConsumed
                                : (
                                    (roundedUpMonthlyEnergyConsumed * 1) /
                                    1000
                                  ).toFixed(2)}
                            </Typography>
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white font-300 ml-4 mt-16'
                                  : 'text-white font-300 ml-4 mt-60'
                              }
                            >
                              {roundedUpMonthlyEnergyConsumed.toString()
                                .length < 10
                                ? 'kWh'
                                : 'MWh'}
                            </Typography>
                          </div>
                          <Typography
                            className={
                              'text-white ml-4 uppercase font-300 whitespace-no-wrap'
                            }
                          >
                            Monthly energy consumption
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Real time energy container */}
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center">
                          <div className="flex flex-row md:justify-center">
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white text-28 font-600 ml-4 uppercase mt-4'
                                  : 'text-white text-64 font-600 ml-4 uppercase'
                              }
                            >
                              {roundedUpEnergyGenerated.toString().length < 10
                                ? roundedUpEnergyGenerated
                                : (
                                    (roundedUpEnergyGenerated * 1) /
                                    1000
                                  ).toFixed(2)}
                            </Typography>
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white font-300 ml-4 mt-16'
                                  : 'text-white font-300 ml-4 mt-60'
                              }
                            >
                              {roundedUpEnergyGenerated.toString().length < 10
                                ? 'kW'
                                : 'MW'}
                            </Typography>
                          </div>
                          <Typography
                            className={'text-white ml-4 uppercase font-300'}
                          >
                            Real time energy generation
                          </Typography>
                        </div>

                        {/* Real time power generation */}
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center">
                          <div className="flex flex-row md:justify-center">
                            <Typography
                              className={
                                isMobile
                                  ? 'text-28 text-white font-100 uppercase mt-4'
                                  : 'text-64 text-white font-100 uppercase'
                              }
                            >
                              {roundedUpPowerGenerated.toString().length < 10
                                ? roundedUpPowerGenerated
                                : (
                                    (roundedUpPowerGenerated * 1) /
                                    1000
                                  ).toFixed(2)}
                            </Typography>
                            <Typography
                              className={
                                isMobile
                                  ? 'text-white font-300 ml-4 mt-16'
                                  : 'text-white font-300 ml-4 mt-60'
                              }
                            >
                              {roundedUpPowerGenerated.toString().length < 10
                                ? 'kW'
                                : 'MW'}
                            </Typography>
                          </div>
                          <Typography
                            className={
                              'text-white ml-4 uppercase font-300 whitespace-no-wrap'
                            }
                          >
                            Real time power generation
                          </Typography>
                        </div>
                      </>
                    )}

                    {/* Contact system suppor container */}
                    <div
                      className={
                        isMobile
                          ? 'flex flex-1 flex-row items-center justify-center md:flex-row md:items-center md:justify-center mt-12'
                          : 'flex flex-1 flex-col items-center justify-center md:flex-col md:items-center md:justify-center'
                      }
                    >
                      <div className="flex flex-col items-center justify-center md:justify-center">
                        <Typography
                          color="secondary"
                          className={'text-10'}
                          to="/support"
                          component={Link}
                        >
                          Trouble? Contact System Support
                        </Typography>
                        <Typography
                          className={classNames(
                            classes.inverterText,
                            'text-20 font-800 uppercase mt-28'
                          )}
                        >
                          {status ? 'online' : 'offline'}
                        </Typography>
                        <Typography className={'text-white uppercase'}>
                          System status
                        </Typography>
                      </div>
                      <div className="flex flex-col items-center justify-center md:justify-center">
                        <div className="flex flex-1 flex-row items-center justify-center md:flex-row md:items-center md:justify-center mt-16">
                          {limitedInverterArray.map((limitedInverter, index) =>
                            this.displayInverterIcon(
                              limitedInverter.status,
                              index
                            )
                          )}
                        </div>

                        <div className="flex">
                          <Typography
                            className={
                              'text-white ml-4 mt-20 uppercase font-300'
                            }
                          >
                            Inv.status
                          </Typography>
                          <Typography
                            className={
                              'text-white ml-4 mt-20 uppercase font-400'
                            }
                          >
                            {online_inverters}/
                            {inverters ? inverters.inverters.length : 0}
                          </Typography>
                        </div>

                        <div className="flex">
                          <Typography
                            className={
                              'text-white ml-4 mt-20 uppercase font-300'
                            }
                          >
                            Alerts :
                          </Typography>
                          <Typography
                            className={
                              'text-white ml-4 mt-20 uppercase font-400'
                            }
                          >
                            {alarmCount}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        }
        content={
          <LocationPageContent
            accountId={this.state.accountId}
            locationId={this.state.locationId}
            inverters={this.state.inverters}
            onChangeToZoneTab={(_value) =>
              this.setState({ isOnZoneTab: _value })
            }
          />
        }
      ></FusePageSimple>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLocation: locationActions.getLocation,
      getOverview: locationActions.getOverview,
      getMetersId: locationActions.getMetersId,
    },
    dispatch
  );
}

function mapStateToProps({ loc, auth }) {
  console.log('map loc', loc);
  return {
    alarmCount: loc.location.alarmsAndAlerts.data.collectionCount,
    location: loc.location,
    overview: loc.overview,
    metersId: loc.metersId,
    chart: loc.chart,
    user: auth.user,
  };
}

export default withReducer('loc', reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Location)
    )
  )
);
