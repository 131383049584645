import React, { Component } from "react";
import { Avatar, Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const propTypes = {};

const defaultProps = {};

const styles = theme => ({});

class PylonToolbarProfile extends Component {
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  getUserRole = roleId => {
    if (roleId == 99) {
      return "Master Admin";
    } else if (roleId == 30) {
      return "Account Admin";
    } else if (roleId == 20) {
      return "Location Admin";
    } else if (roleId == 0) {
      return "Member";
    }

    return "Unavailable";
  };

  render() {
    const { user } = this.props;

    return (
      <div className="flex items-center">
        {user.data.photoURL && user.data.photoURL.length > 0 ? (
          <Avatar className="" alt="user photo" src={user.data.photoURL} />
        ) : (
          <Avatar className="">{user.data.displayName[0]}</Avatar>
        )}

        <div className="hidden md:flex flex-col ml-12 items-start">
          <div className="flex flex-row items-center">
            <Typography component="span" className="normal-case font-600">
              {user.data.displayName}
            </Typography>

            <Icon className="text-16 ml-6" variant="action">
              arrow_drop_down
            </Icon>
          </div>

          <Typography className="text-11 capitalize" color="textSecondary">
            {this.getUserRole(user.role)}
          </Typography>
        </div>
      </div>
    );
  }
}

PylonToolbarProfile.propTypes = propTypes;
PylonToolbarProfile.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user
  };
}

export default withStyles(styles, {
  withTheme: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PylonToolbarProfile)
);
