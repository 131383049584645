import { DateTimePicker } from "material-ui-pickers";
import { fieldRenderProps } from "../../types";
import fieldToDateTimePicker from "./fieldToDateTimePicker";

const DateTimePickerFormik = fieldToDateTimePicker(DateTimePicker);

DateTimePickerFormik.propTypes = {
  ...fieldRenderProps,
  ...DateTimePicker.propTypes,
};

DateTimePickerFormik.defaultProps = {
  variant: "outlined",
};

export default DateTimePickerFormik;
