import { authRoles } from "auth";
import Location from "./Location";

export const LocationConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false
        },
        // navbar: {
        //   display: false
        // },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          disply: false
        }
      }
    }
  },
  auth: authRoles.new,
  routes: [
    {
      path: "/location/:accountId/:locationId",
      component: Location
    }
  ]
};
