import { DatePicker } from "material-ui-pickers";
import fieldToDateTimePicker from "./fieldToDateTimePicker";
import { fieldRenderProps } from "../../types";

const DatePickerFormik = fieldToDateTimePicker(DatePicker);

DatePickerFormik.propTypes = {
  ...fieldRenderProps,
  ...DatePicker.propTypes,
};

DatePickerFormik.defaultProps = {
  variant: "outlined",
};

export default DatePickerFormik;
