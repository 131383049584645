import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
// import BellAlert from '../../../assets/bellAlert';
// import TriangleAlert from '../../../assets/triangleAlert';
import InverterIcon from '../../../../assets/inverterIcon';

const constColorCode = {
  major: '#ec2027',
  minor: '#f79420',
  warning: '#f7ec13',
  offline: '#666766',
  online: '#6ABD45',
  default: '#666766'
};

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  heading: {
    color: constColorCode.default,
    fontSize: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
    padding: '5px',
    display: 'flex',
    alignItems: 'baseline'
  },
  deactivedExpansionPanel: {
    opacity: 0.6,
    backgroundColor: '#B0B2B0'
  },
  expansionPanel: {
    boxShadow: 'none',
    border: '2px solid #DBDBDD',
    margin: '10px'
  },
  panelHeaderText: {
    fontSize: theme.typography.pxToRem(10),
    color: constColorCode.default
  },
  labelText: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    paddingLeft: '1rem'
  },
  defaultText: {
    color: constColorCode.default,
    fontSize: theme.typography.pxToRem(12)
  },
  energyNumber: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(30),
    paddingLeft: '1rem',
    paddingRight: '0.5rem'
  },
  powerNumber: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(30),
    paddingLeft: '1rem',
    paddingRight: '0.5rem'
  },
  paddingLeftRight: {
    paddingLeft: '1rem',
    paddingRight: '0.5rem'
  }
});

class ControlledExpansionPanels extends Component {
  getInverterIconDisplay = status => {
    let iconToDisplay = '';
    switch (status) {
      case 'major':
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.major}></InverterIcon>
        );
        break;
      case 'minor':
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.minor}></InverterIcon>
        );
        break;
      case 'warning':
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.warning}></InverterIcon>
        );
        break;
      case 'online':
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.online}></InverterIcon>
        );
        break;
      case 'offline':
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.offline}></InverterIcon>
        );
        break;
      default:
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.default}></InverterIcon>
        );
        break;
    }
    return iconToDisplay;
  };

  render() {
    const { classes, data } = this.props;
    const {
      gen_meter_id,
      model,
      label,
      status,
      gen_active_energy,
      gen_active_power,
      gen_frequency,
      gen_power_factor,
      logger_cpu_temp
    } = data;

    let roundedUpPowerGenerated = (gen_active_power * 1).toFixed(2);
    let roundedUpEnergyGenerated = (gen_active_energy * 1).toFixed(2);

    return (
      <div className={classes.root}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            className={
              status === 'offline' ? classes.deactivedExpansionPanel : null
            }
          >
            <div className="w-full flex flex-col">
              <div className="w-full flex justify-start flex-wrap">
                <Typography className={classes.panelHeaderText}>
                  <strong>Model</strong>: {model}
                </Typography>
              </div>

              <div className="w-full flex mt-8 items-center justify-between flex-wrap">
                <div className={classes.heading}>
                  {/* <Typography className={classes.defaultText}>ID:</Typography> */}
                  <Typography className={classes.labelText}>{label}</Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.defaultText}>
                    ENERGY GENERATION
                  </Typography>
                  <Typography className={classes.energyNumber}>
                    {roundedUpEnergyGenerated.toString().length < 10
                      ? roundedUpEnergyGenerated
                      : ((roundedUpEnergyGenerated * 1) / 1000).toFixed(2)}
                  </Typography>
                  <Typography className={classes.defaultText}>
                    {roundedUpEnergyGenerated.toString().length < 10
                      ? 'kWh'
                      : 'MWh'}
                  </Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.defaultText}>
                    POWER GENERATION
                  </Typography>
                  <Typography className={classes.powerNumber}>
                    {roundedUpPowerGenerated.toString().length < 10
                      ? roundedUpPowerGenerated
                      : ((roundedUpPowerGenerated * 1) / 1000).toFixed(2)}
                  </Typography>
                  <Typography className={classes.defaultText}>
                    {roundedUpPowerGenerated.toString().length < 10
                      ? 'kW'
                      : 'MW'}
                  </Typography>
                </div>
                {/* <div className={classes.heading}>
                  <Typography className={classes.defaultText}>
                    FREQUENCY
                  </Typography>
                  <Typography className={classes.powerNumber}>
                    {(gen_frequency * 1).toFixed(2)}
                  </Typography>
                  <Typography className={classes.defaultText}>Hz</Typography>
                </div> */}
                {/* <div className={classes.heading}>
                  {this.getInverterIconDisplay(status)}
                </div> */}
              </div>
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(ControlledExpansionPanels);
