import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string().required("Name is a required field!"),
  password: Yup.string().min(6, "Minimum 6 characters password"),
  contact: Yup.number()
    .typeError("Contact Number must be a ‘number’ type!")
    .required("Contact Number is a required field!"),
  email: Yup.string()
    .email("Please input a valid email")
    .required("Email is a required field!"),
  confirmPassword: Yup.string()
    .min(6, "Minimum 6 characters password")
    .when("password", {
      is: function(val) {
        if (val) {
          return val;
        }
      },
      then: Yup.string()
        .min(6, "Minimum 6 characters password")
        .required("New Password is a required field")
        .notOneOf(
          [Yup.ref("password"), null],
          "Old Password and New Password is the same"
        )
    })
});
