import jwtService from 'jwtService';
import firebaseService from 'firebaseService';

export const GET_USER_PROFILE = '[PROFILE] Get User Profile';
export const ERROR_USER_PROFILE = '[PROFILE] Error';

export function getUserProfile(userId) {
    return (dispatch) => {
        jwtService.getUserDataFromServer(userId)
            .then(user => {
                return dispatch({
                    type: GET_USER_PROFILE,
                    payload: user
                });
            })
            .catch(error => {
                return dispatch({
                    type: ERROR_USER_PROFILE,
                    payload: error
                });
            });
    };
}