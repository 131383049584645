import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string().required("Name is a required field!"),
  password: Yup.string()
    .required("Password is a required field!")
    .min(6, "Minimum 6 characters password"),
  confirmPassword: Yup.string()
    .required("Confirm Password is a required field!")
    .oneOf([Yup.ref("password"), null], "Password does not matches"),
  role: Yup.string().required("Role is a required field!"),
  contact_number: Yup.number()
    .typeError("Contact Number must be a ‘number’ type!")
    .required("Contact Number is a required field!"),
  email: Yup.string()
    .email("Please input a valid email")
    .required("Email is a required field!")
  //   region: Yup.array()
  //     .of(Yup.string())
  //     .required()
});
